import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ErrorSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9987 3.3335C6.32536 3.3335 3.33203 6.32683 3.33203 10.0002C3.33203 13.6735 6.32536 16.6668 9.9987 16.6668C13.672 16.6668 16.6654 13.6735 16.6654 10.0002C16.6654 6.32683 13.672 3.3335 9.9987 3.3335ZM12.2387 11.5335C12.432 11.7268 12.432 12.0468 12.2387 12.2402C12.1387 12.3402 12.012 12.3868 11.8854 12.3868C11.7587 12.3868 11.632 12.3402 11.532 12.2402L9.9987 10.7068L8.46536 12.2402C8.36536 12.3402 8.2387 12.3868 8.11203 12.3868C7.98536 12.3868 7.8587 12.3402 7.7587 12.2402C7.56536 12.0468 7.56536 11.7268 7.7587 11.5335L9.29203 10.0002L7.7587 8.46683C7.56536 8.2735 7.56536 7.9535 7.7587 7.76016C7.95203 7.56683 8.27203 7.56683 8.46536 7.76016L9.9987 9.2935L11.532 7.76016C11.7254 7.56683 12.0454 7.56683 12.2387 7.76016C12.432 7.9535 12.432 8.2735 12.2387 8.46683L10.7054 10.0002L12.2387 11.5335Z"
      fill="#D61F56"
    />
  </svg>
);

const ErrorIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ErrorSvg} {...props} />
);

export default ErrorIcon;
