import { combineReducers } from "@reduxjs/toolkit";
import account from "../organisms/MyAccount/ducks/reducers";
import members from "../organisms/Members/ducks/reducers";
import events from "../organisms/Events/ducks/reducers";
import settings from "../organisms/SystemSettings/ducks/reducers";

const rootReducer = combineReducers({
  members,
  account,
  events,
  settings,
});

export default rootReducer;
