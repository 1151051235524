import { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button, Form } from "antd";
import { useNavigate } from "react-router-dom";
import InputField from "../../../molecules/FormItems/InputField";
import FullPageLayout from "../../../templates/FullPageLayout";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import SelectField from "../../../molecules/FormItems/SelectField";
import { addOperationTeamMember } from "../ducks/services";
import { onImageDelete, uploadUserImage } from "../../MyAccount/ducks/services";
import { AxiosError, AxiosResponse } from "axios";
import { CloseIcon } from "../../../atoms/CustomIcons";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  type: string;
};

export default function CreateMembers() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
      type: val?.type,
    };
    addOperationTeamMember(payload)
      .then((res: AxiosResponse) => {
        setLoading(true);

        if (checklistFile?.uid && res && res?.data?.id) {
          uploadUserImage({
            owner_id: res?.data?.id,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "user_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("User added.");
                setUploadModal(false);
                setLoading(false);
                navigate("/system-settings");
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          SuccessMessage("User added.");
          navigate("/system-settings");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const typeOptions = [
    { label: "Super Admin", value: "admin" },
    { label: "Community Manager", value: "manager" },
  ];

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    setLoading(false);
    setUploadModal(false);
  };

  return (
    <FullPageLayout loading={loading}>
      <div className="invoice_head">
        <div className="left_head">
          <h3>Add new operations team user</h3>
        </div>
      </div>
      <div className="page_center add_user_form">
        <div className="center_all">
          <div className="center_head">
            <h3>User details</h3>
            <p>Fill in the information about the operations team user.</p>
          </div>
          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={24}>
              <Col span={24}>
                <h4 style={{ marginBottom: 12 }}>Profile photo</h4>
                <UploadPhoto
                  uploadAvatar="/images/upload-avatar2.svg"
                  width={88}
                  height={88}
                  saveUpload={saveUpload}
                  onImageDelete={() => {
                    onImageDelete(
                      setLoading,
                      SuccessMessage,
                      () => {},
                      () => {},
                      ErrorMessage,
                      undefined
                    );
                  }}
                  loading={loading}
                  uploadModal={uploadModal}
                  setUploadModal={setUploadModal}
                  previewImage={previewImage}
                  checklistFile={checklistFile}
                  modalPreviewImage={modalPreviewImage}
                  setChecklistFile={setChecklistFile}
                  setPreviewImage={setPreviewImage}
                  setModalPreviewImage={setModalPreviewImage}
                />
              </Col>

              <Col span={12}>
                <h4 className="label_text">
                  First & Last Names <span className="red_color">*</span>{" "}
                </h4>
                <p style={{ marginBottom: "12px" }}>
                  Are visible on the platform to all its users.
                </p>
                <InputField
                  fieldname="first_name"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter First Name",
                  }}
                  isRequired
                  rules={{
                    required: "First name is required.",
                  }}
                  validate={errors.first_name && "error"}
                  validMessage={errors.first_name && errors.first_name.message}
                />
              </Col>
              <Col span={12}>
                <h4> &nbsp; </h4>
                <p style={{ marginBottom: "12px" }}> &nbsp; </p>
                <InputField
                  fieldname="last_name"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Last Name",
                  }}
                  isRequired
                  rules={{
                    required: "Last name is required.",
                  }}
                  validate={errors.last_name && "error"}
                  validMessage={errors.last_name && errors.last_name.message}
                />
              </Col>

              <div className="divider"></div>

              <Col span={12}>
                <h4 className="label_text">
                  Email address <span className="red_color">*</span>
                </h4>
                <p style={{ marginBottom: "12px" }}>
                  {" "}
                  Is used to log in to the platform.{" "}
                </p>
                <InputField
                  fieldname="email"
                  label=""
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter your email",
                  }}
                  isRequired
                  rules={{
                    required: "Email is required.",
                    pattern: {
                      value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Email is required.",
                    },
                  }}
                  validate={errors.email && "error"}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>

              <div className="divider"></div>

              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="type"
                  label="Role"
                  control={control}
                  selectOption={typeOptions}
                  isRequired={true}
                  rules={{ required: "Role is required" }}
                  iProps={{
                    placeholder: "Choose the role",
                    placement: "topLeft",
                  }}
                  validate={errors.type && "error"}
                  validMessage={errors.type && errors.type.message}
                />
              </Col>
            </Row>

            <div className="discard_wrapper">
              <div className="discard_next_card">
                <div className="dn_left">
                  <Button
                    htmlType="button"
                    onClick={() => navigate("/system-settings")}
                    type="text"
                    icon={<CloseIcon />}
                  >
                    Discard & Exit
                  </Button>
                </div>
                <div className="dn_right">
                  <Button htmlType="submit" className="icon_dark_btn">
                    Save User
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </FullPageLayout>
  );
}
