import { Row, Col, Tabs } from "antd";
import type { TabsProps } from "antd";
import DashboardTemplate from "../../templates/Dashboard";
import OperationsTeam from "./OperationsTeam";
import CancelationPolicy from "./CancelationPolicy";

export default function SystemSettings() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Operations team",
      children: <OperationsTeam />,
    },
    {
      key: "2",
      label: "Cancelation policy",
      children: <CancelationPolicy />,
    },
  ];

  return (
    <DashboardTemplate loading={false}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="page_title">
            <h3>System settings</h3>
          </div>
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey="1" items={items} tabPosition="left" />
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
