import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Tag,
  Avatar,
  Button,
  Select,
  Radio,
  Input,
  Image,
  RadioChangeEvent,
} from "antd";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import DashboardTemplate from "../../templates/Dashboard";
import {
  CloseIcon,
  DropdownIcon,
  PlusIcon,
  SearchIcon,
  SortIcon,
} from "../../atoms/CustomIcons";
import EmptyCard from "../../molecules/EmptyCard";
import { useAppSelector, useAppDispatch } from "../../config/hooks";
import {
  getCities,
  getMembersList,
  setFiltersOptionsMember,
} from "./ducks/actions";
import { UserCompanyIndustries } from "../../config/constants";

const { Option } = Select;

interface DataType {
  key: React.Key;
  company_amount_raised: string;
  company_desc: string;
  company_headquarter_location: string;
  company_name: string;
  company_revenue: string;
  company_website: string;
  company_industry: string;
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  position: string;
  short_location: string;
  status: string;
  files: { id: string; url: string; owner_id: string }[];
}

export default function Members() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filterBtn, setFilterBtn] = useState(false);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const membersList = useAppSelector(
    (state) => state?.members?.membersList?.listData
  );
  const membersListLoading = useAppSelector(
    (state) => state?.members?.membersList?.loading
  );
  const citiesList = useAppSelector((state) => state?.members?.citiesList);
  const selectedFilters = useAppSelector((state) => state?.members?.filter);
  const [searchValue, setSearchValue] = useState("");
  const [citiesDropdown, setCitiesDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    if (citiesList?.length > 0) {
      let citiesArray: { label: string; value: string }[] = [];
      citiesList?.map((e: { name: string }) => {
        citiesArray.push({
          label: e?.name,
          value: e?.name,
        });
        return citiesArray;
      });
      setCitiesDropdown(citiesArray);
    }
  }, [citiesList]);

  useEffect(() => {
    if (membersList?.length > 0) {
      setDataSource(membersList);
    } else {
      setDataSource([]);
    }
  }, [membersList]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Member",
      dataIndex: "first_name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <div className="table_with_image">
          {record?.files?.length > 0 ? (
            <Image
              src={record?.files[0].url}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
            />
          ) : (
            <Avatar size={28} shape="square" />
          )}
          <div className="table_text">
            <span className="main_text">
              {record?.first_name + " " + record?.last_name}
            </span>
            <span className="email_text">{record?.email}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      sorter: (a, b) => a?.position?.localeCompare(b?.position),
      sortIcon: () => <SortIcon />,
      render: (text) => (text === "CoFounder" ? "Co-Founder" : text),
    },
    {
      title: "Location",
      dataIndex: "company_headquarter_location",
      sorter: (a, b) =>
        a?.company_headquarter_location?.localeCompare(
          b?.company_headquarter_location
        ),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Company",
      dataIndex: "company_name",
      render: (text) => (text ? text : "-"),
      sorter: (a, b) => a?.company_name?.localeCompare(b?.company_name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Industry",
      dataIndex: "company_industry",
      render: (text: keyof typeof UserCompanyIndustries) =>
        text ? UserCompanyIndustries[text] : "-",
      sorter: (a, b) => a?.company_industry?.localeCompare(b?.company_industry),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Capital raised",
      dataIndex: "company_amount_raised",
      width: 120,
      render: (text) => (
        <span className="table_text_right">
          {text && text !== "$0" ? text : "-"}
        </span>
      ),
      sorter: (a, b) =>
        a?.company_amount_raised?.localeCompare(b?.company_amount_raised),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Revenue",
      dataIndex: "company_revenue",
      sorter: (a, b) => a?.company_revenue?.localeCompare(b?.company_revenue),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let clname = "";
        if (text?.includes("1 - 5M")) {
          clname = "pink_tag";
        } else if (text?.includes("6 - 10M")) {
          clname = "orange_tag";
        } else if (text?.includes("11 - 20M")) {
          clname = "dark_pink_tag";
        } else if (text?.includes("21 - 50M")) {
          clname = "green_tag";
        } else if (text?.includes("50M+")) {
          clname = "dark_orange_tag";
        } else {
          clname = "undisclosed";
        }
        return (
          <Tag style={{ fontSize: "14px" }} className={`defaultTag ${clname}`}>
            {text}
          </Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 52,
      render: () => (
        <Image
          src="/images/right-arrow.svg"
          width={40}
          height={40}
          alt="Arrow"
          preview={false}
        />
      ),
    },
  ];

  const revenueDropdown = [
    {
      value: "$1 - 5M",
      label: <Tag className="defaultTag pink_tag">$1 - 5M+</Tag>,
    },
    {
      value: "$6 - 10M",
      label: <Tag className="defaultTag orange_tag">$6 - 10M+</Tag>,
    },
    {
      value: "$11 - 20M",
      label: <Tag className="defaultTag dark_pink_tag">$11 - 20M+</Tag>,
    },
    {
      value: "$21 - 50M",
      label: <Tag className="defaultTag green_tag">$21 - 50M+</Tag>,
    },
    {
      value: "$50M",
      label: <Tag className="defaultTag dark_orange_tag">$50M+</Tag>,
    },
    {
      value: "Undisclosed",
      label: <Tag className="defaultTag undisclosed">Undisclosed</Tag>,
    },
  ];

  const industryDropdown = [
    {
      label: "Aerospace & Defense",
      value: "aerospacedefense",
    },
    {
      label: "Agriculture/Livestock",
      value: "agriculturelivestock",
    },
    {
      label: "Automotive",
      value: "automotive",
    },
    {
      label: "Business Services (Printing, etc.)",
      value: "businessservices",
    },
    {
      label: "Construction (Contractor, Home Building)",
      value: "construction",
    },
    {
      label: "Consumer Services",
      value: "consumerservices",
    },
    {
      label: "Education/Training",
      value: "educationtraining",
    },
    {
      label: "Private Equity",
      value: "privateequity",
    },
  ];

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        dispatch(setFiltersOptionsMember("status", "active"));
        navigate(`/member-detail/${record?.id}`);
      },
    };
  };

  const resetFilters = () => {
    dispatch(setFiltersOptionsMember("searchMembers", undefined));
    dispatch(setFiltersOptionsMember("location", undefined));
    dispatch(setFiltersOptionsMember("industry", undefined));
    dispatch(setFiltersOptionsMember("revenue", undefined));
    dispatch(setFiltersOptionsMember("status", undefined));
    setFilterBtn(false);
    setSearchValue("");
  };

  const handleSearch = useCallback(
    debounce((value) => {
      dispatch(setFiltersOptionsMember("searchMembers", value));
    }, 500),
    []
  );

  const handleChange = (event: { target: { value: any } }) => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  };

  useEffect(() => {
    resetFilters();
  }, []);

  useEffect(() => {
    const payload = {
      search: selectedFilters?.searchMembers
        ? selectedFilters?.searchMembers
        : undefined, //Optional
      status: selectedFilters?.status ?? "active", //Optional: "Active" by default
      location:
        selectedFilters?.location?.length > 0
          ? selectedFilters?.location
          : undefined, //Optional
      industry:
        selectedFilters?.industry?.length > 0
          ? selectedFilters?.industry
          : undefined, //Optional
      revenue:
        selectedFilters?.revenue?.length > 0
          ? selectedFilters?.revenue
          : undefined, //Optional
      //position: ["CTO"], //Optional
      offset: selectedFilters?.searchMembers
        ? 0
        : pagination > 1
        ? limit * (pagination - 1)
        : pagination - 1,
      limit: limit,
      type: ["member"],
    };
    dispatch(getMembersList(payload));
  }, [selectedFilters, pagination, limit, dispatch]);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  return (
    <DashboardTemplate loading={membersListLoading}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="page_title">
            <h3>Members</h3>
          </div>
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <div className="search_filter">
                  <Input
                    placeholder="Search members"
                    prefix={<SearchIcon />}
                    value={searchValue}
                    onChange={handleChange}
                  />
                </div>
                <div className="filter_right">
                  <div className="filter_btn">
                    <Button onClick={() => setFilterBtn(!filterBtn)}>
                      Filter
                      <Image
                        src="/images/filter-icon.svg"
                        alt="Filter"
                        width={16}
                        height={16}
                        preview={false}
                      />
                    </Button>
                  </div>
                  <div className="add_new_btn">
                    <Button
                      className="icon_dark_btn"
                      icon={<PlusIcon />}
                      onClick={() => navigate("/create-member")}
                    >
                      Add member
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            {filterBtn && (
              <Col span={24}>
                <div className="filter_div">
                  <div className="filter_radio">
                    <Radio.Group
                      defaultValue="active"
                      onChange={({ target: { value } }: RadioChangeEvent) =>
                        dispatch(setFiltersOptionsMember("status", value))
                      }
                    >
                      <Radio.Button value="active">Active</Radio.Button>
                      <Radio.Button value="deactivated">
                        Deactivated
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                  <div className="filter_search">
                    <Select
                      placeholder="Location"
                      suffixIcon={<DropdownIcon />}
                      mode="multiple"
                      maxTagCount={1}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsMember("location", e))
                      }
                      popupClassName="location_popup"
                      style={{ minWidth: "101px" }}
                      options={citiesDropdown}
                    />
                    <Select
                      placeholder="Industry"
                      suffixIcon={<DropdownIcon />}
                      mode="multiple"
                      maxTagCount={1}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsMember("industry", e))
                      }
                      popupClassName="industry_popup"
                      style={{ minWidth: "101px" }}
                      options={industryDropdown}
                    />
                    <Select
                      placeholder="Revenue"
                      suffixIcon={<DropdownIcon />}
                      mode="multiple"
                      maxTagCount={1}
                      className="revenue_dropdown"
                      popupClassName="revenue_popup"
                      onChange={(e) =>
                        dispatch(setFiltersOptionsMember("revenue", e))
                      }
                      style={{ minWidth: "101px" }}
                      options={revenueDropdown}
                    />
                  </div>
                  <div className="reset_btn">
                    <Button
                      onClick={resetFilters}
                      type="text"
                      icon={<CloseIcon />}
                    >
                      Reset Filters
                    </Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          {membersList?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={membersList?.data}
              onRow={onClickRow}
              pagination={{
                // showSizeChanger: membersList?.data?.length > limit,
                defaultPageSize: limit,
                total: membersList?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
            />
          )}

          {!selectedFilters?.searchMembers &&
            membersList?.data?.length === 0 && (
              <EmptyCard
                image="/images/members/empty-card-icon.svg"
                title={
                  selectedFilters?.status === "deactivated"
                    ? "There are no deactivated members."
                    : "There are no active members."
                }
                description='Click the "Add member" button to add a new member.'
                buttonText="Add member"
                navigateTo="/create-member"
                showOnlyHeading={selectedFilters?.status === "deactivated"}
              />
            )}

          {selectedFilters?.searchMembers &&
            membersList?.data?.length === 0 && (
              <div
                className="no_data_card"
                style={{ height: "calc(100vh - 200px)" }}
              >
                <div className="no_data_image">
                  <Image
                    src="/images/empty-Icon.svg"
                    preview={false}
                    width={216}
                    height={102}
                  />
                </div>
                <div className="no_data_content">
                  <h4>No members found</h4>
                </div>
              </div>
            )}
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
