import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";
import { Ilogin } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AxiosResponse<Ilogin>> => {
  return axios.post(`${baseURL}login`, { email, password });
};

export const forgotPassword = ({
  email,
}: {
  email: string;
}): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}forgotPassword`, { email });
};

export const resetPassword = ({
  newPassword,
  token,
}: {
  newPassword: string;
  token: string;
}): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  const data = {
    newPassword,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios.post(`${baseURL}resetPassword`, data, config);
};
