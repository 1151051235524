import React from "react";
import { Image, Layout, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const { Content } = Layout;
const antIcon = <Loading3QuartersOutlined spin />;

type layoutProps = {
  loading: boolean;
  children: React.ReactNode;
};

const FullPageLayout = (props: layoutProps) => {
  const { loading, children } = props;
  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Layout className="full_page_layout">
        <div className="bg_top">
          <Image src="/images/bg-top.svg" preview={false} />
        </div>
        <div className="bg_bottom">
          <Image src="/images/bg-bottom.svg" preview={false} />
        </div>
        <Content className="content_area">{children}</Content>
      </Layout>
    </Spin>
  );
};

export default FullPageLayout;
