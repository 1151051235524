import Icon from "@ant-design/icons";

const InvoiceSvg = () => (
<svg width="20" height="20" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.7492 5.1625C17.0316 5.10505 17.2854 4.95182 17.4678 4.72874C17.6501 4.50566 17.7498 4.22642 17.75 3.93828V2C17.75 1.66848 17.6183 1.35054 17.3839 1.11612C17.1495 0.881696 16.8315 0.75 16.5 0.75H1.5C1.16848 0.75 0.850539 0.881696 0.616119 1.11612C0.381698 1.35054 0.250002 1.66848 0.250002 2V3.93828C0.25016 4.22642 0.349858 4.50566 0.532225 4.72874C0.714593 4.95182 0.96843 5.10505 1.25078 5.1625C1.67298 5.25005 2.05209 5.48044 2.32427 5.81485C2.59644 6.14926 2.74505 6.56726 2.74505 6.99844C2.74505 7.42961 2.59644 7.84761 2.32427 8.18202C2.05209 8.51643 1.67298 8.74682 1.25078 8.83437C0.967904 8.89193 0.713664 9.04562 0.531236 9.26935C0.348808 9.49307 0.249439 9.77304 0.250002 10.0617V12C0.250002 12.3315 0.381698 12.6495 0.616119 12.8839C0.850539 13.1183 1.16848 13.25 1.5 13.25H16.5C16.8315 13.25 17.1495 13.1183 17.3839 12.8839C17.6183 12.6495 17.75 12.3315 17.75 12V10.0617C17.7498 9.77358 17.6501 9.49434 17.4678 9.27126C17.2854 9.04818 17.0316 8.89495 16.7492 8.8375C16.327 8.74995 15.9479 8.51956 15.6757 8.18515C15.4036 7.85074 15.255 7.43274 15.255 7.00156C15.255 6.57039 15.4036 6.15239 15.6757 5.81798C15.9479 5.48357 16.327 5.25318 16.7492 5.16562V5.1625ZM1.5 10.0625C2.20601 9.91868 2.84064 9.53531 3.29644 8.97729C3.75224 8.41928 4.00121 7.7209 4.00121 7.00039C4.00121 6.27988 3.75224 5.5815 3.29644 5.02349C2.84064 4.46547 2.20601 4.0821 1.5 3.93828V2H5.875V12H1.5V10.0625ZM16.5 10.0625V12H7.125V2H16.5V3.9375C15.794 4.08132 15.1594 4.46469 14.7036 5.02271C14.2478 5.58072 13.9988 6.2791 13.9988 6.99961C13.9988 7.72012 14.2478 8.4185 14.7036 8.97651C15.1594 9.53453 15.794 9.9179 16.5 10.0617V10.0625Z" fill="#AEAFB2"/>
</svg>
);

const InvoiceIcon = (props: any) => <Icon component={InvoiceSvg} {...props} />;

export default InvoiceIcon;
