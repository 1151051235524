import { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Descriptions,
  Tag,
  Avatar,
  Button,
  Space,
  Image,
  Card,
  Dropdown,
  Breadcrumb,
  Input,
  Select,
} from "antd";
import type { DescriptionsProps, MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardTemplate from "../../../templates/Dashboard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import {
  getCities,
  memberDetails,
  setEventDetailSearch,
} from "../ducks/actions";
import { Link } from "react-router-dom";
import { Popup } from "../../../atoms/Popup";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { deactivateMemberProfile, deleteMember } from "../ducks/services";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";
import {
  UserCompanyFundingSources,
  UserCompanyIndustries,
} from "../../../config/constants";
import {
  ArrowIconRight,
  BackArrowIcon,
  DropdownIcon,
  SearchIcon,
} from "../../../atoms/CustomIcons";
import dayjs from "dayjs";
import EventCards from "../../../atoms/EventCards";
import { getEventFilters } from "../../Events/ducks/actions";

const { Meta } = Card;
const { Option } = Select;

export default function MembersDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const memberID = location?.pathname?.split("/")[2];
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState<boolean>(false);
  const [trailData, setTrailData] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<[]>([]);
  const citiesList = useAppSelector((state) => state?.members?.citiesList);
  const eventFilters = useAppSelector((state) => state?.events?.eventFilters);
  const [citiesDropdown, setCitiesDropdown] = useState<
    { label: string; value: string }[]
  >([]);

  const memberDetailsData = useAppSelector(
    (state) => state?.members?.memberDetails?.memberData
  );
  const memberLoader = useAppSelector(
    (state) => state?.members?.memberDetails?.loading
  );
  const filterSearchData = useAppSelector(
    (state) => state?.members?.filterSearch
  );

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (memberID) {
      dispatch(memberDetails(memberID));
    }
  }, [dispatch, memberID]);

  const personalItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: (
        <p>
          <Image
            src="/images/members/resources.svg"
            alt="resources"
            width={16}
            height={16}
            preview={false}
          />
          Top business books
        </p>
      ),
      children: (
        <ul>
          {memberDetailsData?.userData?.fav_business_books?.length > 0 ? (
            memberDetailsData?.userData?.fav_business_books?.map(
              (e: string) => <li>{e}</li>
            )
          ) : (
            <p>-</p>
          )}
        </ul>
      ),
    },
    {
      key: "2",
      label: (
        <p>
          <Image
            src="/images/members/resources.svg"
            alt="resources"
            width={16}
            height={16}
            preview={false}
          />
          Top personal books
        </p>
      ),
      children: (
        <ul>
          {memberDetailsData?.userData?.fav_personal_books?.length > 0 ? (
            memberDetailsData?.userData?.fav_personal_books?.map(
              (e: string) => <li>{e}</li>
            )
          ) : (
            <p>-</p>
          )}
        </ul>
      ),
    },
  ];

  const companyItems: {
    description: keyof typeof UserCompanyIndustries | string;
    key: string;
    label: JSX.Element;
    col: number;
  }[] = [
    {
      key: "1",
      col: 24,
      label: (
        <h5>
          <Image
            src="/images/members/industry.svg"
            alt="name"
            width={16}
            height={16}
            preview={false}
          />
          Name
        </h5>
      ),
      description: memberDetailsData?.userData?.company_name
        ? memberDetailsData?.userData?.company_name
        : "-",
    },
    {
      key: "2",
      col: 24,
      label: (
        <h5>
          <Image
            src="/images/members/description.svg"
            alt="description"
            width={16}
            height={16}
            preview={false}
          />
          Description
        </h5>
      ),
      description: memberDetailsData?.userData?.company_desc
        ? memberDetailsData?.userData?.company_desc
        : "-",
    },
    {
      key: "3",
      col: 24,
      label: (
        <h5>
          <Image
            src="/images/members/website.svg"
            alt="website"
            width={16}
            height={16}
            preview={false}
          />
          Website
        </h5>
      ),
      description: memberDetailsData?.userData?.company_website
        ? memberDetailsData?.userData?.company_website
        : "-",
    },
    {
      key: "4",
      col: 24,
      label: (
        <h5>
          <Image
            src="/images/members/fund.svg"
            alt="fund"
            width={16}
            height={16}
            preview={false}
          />
          Funding source
        </h5>
      ),
      description: memberDetailsData?.userData?.company_funding_source
        ? UserCompanyFundingSources[
            memberDetailsData?.userData
              ?.company_funding_source as keyof typeof UserCompanyFundingSources
          ]
        : "-",
    },
    {
      key: "5",
      col: 12,
      label: (
        <h5>
          <Image
            src="/images/members/raised.svg"
            alt="raised"
            width={16}
            height={16}
            preview={false}
          />
          Amount raised
        </h5>
      ),
      description:
        memberDetailsData?.userData?.company_amount_raised !== "$0"
          ? memberDetailsData?.userData?.company_amount_raised
          : "-",
    },
    {
      key: "6",
      col: 12,
      label: (
        <h5>
          <Image
            src="/images/members/money.svg"
            alt="Revenue"
            width={16}
            height={16}
            preview={false}
          />
          Revenue
        </h5>
      ),
      description: memberDetailsData?.userData?.company_revenue
        ? memberDetailsData?.userData?.company_revenue
        : "-",
    },
    {
      key: "7",
      col: 24,
      label: (
        <h5>
          <Image
            src="/images/members/location.svg"
            alt="location"
            width={16}
            height={16}
            preview={false}
          />
          Headquarters location
        </h5>
      ),
      description: memberDetailsData?.userData?.company_headquarter_location
        ? memberDetailsData?.userData?.company_headquarter_location
        : "-",
    },
    {
      key: "8",
      col: 24,
      label: (
        <h5>
          <Image
            src="/images/members/briefcase.svg"
            alt="Industry"
            width={16}
            height={16}
            preview={false}
          />
          Industry
        </h5>
      ),
      description: memberDetailsData?.userData?.company_industry
        ? UserCompanyIndustries[
            memberDetailsData?.userData
              ?.company_industry as keyof typeof UserCompanyIndustries
          ]
        : "-",
    },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <Link
          to={`/update-member/${memberDetailsData?.userData?.id}`}
          className="footer_dropdown"
        >
          <img src="/images/dropdown/edit.svg" alt="" /> Edit profile
        </Link>
      ),
      key: "1",
      disabled: memberDetailsData?.userData?.status === "deactivated",
    },
    {
      label: (
        <Button
          className="footer_logout"
          onClick={() =>
            memberDetailsData?.userData?.status === "deactivated"
              ? activateMember()
              : setConfirmDeactivate(true)
          }
        >
          {memberDetailsData?.userData?.status === "deactivated" ? (
            <>
              <img src="/images/activate.svg" alt="" /> Activate member
            </>
          ) : (
            <>
              <img src="/images/deactivate.svg" alt="" /> Deactivate member
            </>
          )}
        </Button>
      ),
      key: "2",
    },
    {
      label: (
        <Button
          className="footer_logout"
          onClick={() => setConfirmDelete(true)}
        >
          <img src="/images/dropdown/delete.svg" alt="" /> Delete member
        </Button>
      ),
      key: "3",
    },
  ];

  const deleteFunction = () => {
    setLoading(true);
    deleteMember(memberID)
      .then(() => {
        SuccessMessage("Member deleted.");
        setConfirmDelete(false);
        setLoading(false);
        navigate("/members");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          if (e?.response?.data?.error?.statusCode === 409) {
            setTrailData(true);
            setConfirmDeactivate(true);
            setConfirmDelete(false);
          }
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const deactivateFunction = () => {
    setLoading(true);
    const payload = {
      status: "deactivated",
    };
    deactivateMemberProfile(payload, memberID)
      .then(() => {
        SuccessMessage("Member deactivated.");
        setConfirmDelete(false);
        setLoading(false);
        navigate("/members");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          setTrailData(false);
          setConfirmDeactivate(false);
          setConfirmDelete(false);
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const activateMember = () => {
    setLoading(true);

    const payload = {
      status: "active",
    };
    deactivateMemberProfile(payload, memberID)
      .then(() => {
        SuccessMessage("Member activated.");
        setConfirmDelete(false);
        setLoading(false);
        navigate("/members");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          setTrailData(false);
          setConfirmDeactivate(false);
          setConfirmDelete(false);
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const confirmDeleteMember = {
    visibility: confirmDelete,
    class: "upload_photo_modal",
    content: (
      <ConfirmDelete
        title={
          <>
            <img src="/images/alert-icon.svg" alt="delete" /> Delete member
          </>
        }
        onClose={() => setConfirmDelete(false)}
        heading={
          <>
            Are you sure you want to delete{" "}
            <b>
              {memberDetailsData?.userData?.first_name ?? ""}{" "}
              {memberDetailsData?.userData?.last_name ?? ""}
            </b>
            ?
          </>
        }
        paragraph="The member profile will be deleted. This action can't be undone."
        btnText="Delete member"
        btnApi={deleteFunction}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmDelete(false),
  };

  const confirmDeactivateMember = {
    visibility: confirmDeactivate,
    class: "upload_photo_modal",
    content: (
      <ConfirmDelete
        title={
          <>
            <img src="/images/alert-icon.svg" alt="delete" />{" "}
            {trailData ? "Delete member" : "Deactivate member"}
          </>
        }
        onClose={() => setConfirmDeactivate(false)}
        heading={
          trailData ? (
            "The user can be deactivated only"
          ) : (
            <>
              Are you sure you want to deactivate{" "}
              <b>
                {memberDetailsData?.userData?.first_name ?? ""}{" "}
                {memberDetailsData?.userData?.last_name ?? ""}
              </b>
              ?
            </>
          )
        }
        paragraph="The member won't be able to access their account anymore. The data associated with this user (e.g., event bookings, conversations) will be preserved."
        btnText="Deactivate member"
        btnApi={deactivateFunction}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmDeactivate(false),
  };

  useEffect(() => {
    if (memberDetailsData?.userEvents) {
      setDataSource(memberDetailsData?.userEvents);
    }
  }, [memberDetailsData]);

  useEffect(() => {
    const currValue = filterSearchData?.searchDetailMembers?.toLowerCase();
    const currLocation = filterSearchData?.location?.toLowerCase();
    const currType = filterSearchData?.type?.toLowerCase();
    let filteredData = [];

    filteredData = memberDetailsData?.userEvents?.filter((entry: any) => {
      const typedData = currValue
        ? entry?.title && entry?.title?.toLowerCase()?.includes(currValue)
        : true;

      const locationData = currLocation
        ? entry?.city && entry?.city?.toLowerCase() === currLocation
        : true;

      const typeData = currType
        ? entry?.type && entry?.type?.toLowerCase() === currType
        : true;

      return typedData && locationData && typeData;
    });

    setDataSource(filteredData);
  }, [filterSearchData]);

  useEffect(() => {
    dispatch(getCities());
    dispatch(getEventFilters());
  }, [dispatch]);

  useEffect(() => {
    if (citiesList?.length > 0) {
      let citiesArray: { label: string; value: string }[] = [];
      citiesList?.map((e: { name: string }) => {
        citiesArray.push({
          label: e?.name,
          value: e?.name,
        });
        return citiesArray;
      });
      setCitiesDropdown(citiesArray);
    }
  }, [citiesList]);

  return (
    <DashboardTemplate loading={memberLoader || loading}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Breadcrumb
            separator={<ArrowIconRight />}
            items={[
              {
                href: "/members",
                title: (
                  <>
                    <BackArrowIcon /> Members
                  </>
                ),
              },
              {
                title: "Member profile",
              },
            ]}
          />
        </Col>

        {memberDetailsData?.userData?.status === "deactivated" && (
          <Col span={24}>
            <Card className="deactivatedCard">
              <Row gutter={24} justify="space-between" align="middle">
                <Col>
                  <Meta
                    avatar={
                      <Image
                        src="/images/members/deactivated.svg"
                        width={24}
                        height={24}
                        preview={false}
                      />
                    }
                    title="Member deactivated"
                    description={
                      <>
                        This user was archived on{" "}
                        {dayjs(
                          memberDetailsData?.userData?.modified_date
                        ).format("MMM D, YYYY")}
                        . He/she can't access the platform anymore
                      </>
                    }
                  />
                </Col>
                <Col flex="0 0 194px">
                  <Button onClick={activateMember} className="icon_white_btn">
                    <Image
                      src="/images/activate.svg"
                      width={16}
                      height={16}
                      preview={false}
                    />{" "}
                    Activate this member
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        )}

        <Col span={24}>
          <Card className="memberDetailCard">
            <Row gutter={24} justify="space-between" align="middle">
              <Col>
                <Meta
                  avatar={
                    <Avatar
                      src={
                        memberDetailsData?.userData?.files?.length > 0 &&
                        memberDetailsData?.userData?.files[0]?.url
                      }
                      size={70}
                      shape="square"
                    />
                  }
                  title={
                    <>
                      {memberDetailsData?.userData?.first_name ?? ""}{" "}
                      {memberDetailsData?.userData?.last_name ?? ""}{" "}
                      {memberDetailsData?.userData?.status ===
                        "deactivated" && (
                        <Tag className="defaultTag red_outline_tag">
                          Deactivated
                        </Tag>
                      )}
                    </>
                  }
                  description={
                    <>
                      {memberDetailsData?.userData?.position && (
                        <Tag>
                          Position:{" "}
                          <span>
                            {memberDetailsData?.userData?.position ===
                            "CoFounder"
                              ? "Co-Founder"
                              : memberDetailsData?.userData?.position}
                          </span>
                        </Tag>
                      )}
                      {memberDetailsData?.userData?.short_location && (
                        <Tag>
                          Location:{" "}
                          <span>
                            {memberDetailsData?.userData?.short_location}
                          </span>
                        </Tag>
                      )}
                    </>
                  }
                />
              </Col>
              <Col flex="0 0 136px">
                <Space
                  size={8}
                  style={{ justifyContent: "flex-end", width: "100%" }}
                >
                  {memberDetailsData?.userData?.phone && (
                    <a href={`tel:${memberDetailsData?.userData?.phone}`}>
                      <Image
                        src="/images/members/phone-icon.svg"
                        alt="phone"
                        width={40}
                        height={40}
                        preview={false}
                      />
                    </a>
                  )}

                  {memberDetailsData?.userData?.email && (
                    <a href={`mailto:${memberDetailsData?.userData?.email}`}>
                      <Image
                        src="/images/members/newsletter-icon.svg"
                        alt="newsletter"
                        width={40}
                        height={40}
                        preview={false}
                      />
                    </a>
                  )}

                  <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="topRight"
                    overlayClassName="ft_dropdown"
                  >
                    <Button
                      type="text"
                      className="user_info_button"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="user_info">
                        <Image
                          src="/images/members/dots-icon.svg"
                          alt="icon"
                          width={40}
                          height={40}
                          preview={false}
                        />
                      </div>
                    </Button>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col flex="0 0 376px"  className="memberDetailLeft">
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Card className="members_detail_left_card">
                <Descriptions
                  title="Personal details"
                  items={personalItems}
                  layout="vertical"
                  column={1}
                />
              </Card>
            </Col>
            <Col span={24}>
              <Card className="members_detail_left_card">
                {/* <Descriptions
                  title="Company details"
                  items={companyItems}
                  layout="vertical"
                  column={1}
                /> */}
                <div className="member_company_detail">
                  <h4>Company details</h4>
                  <Row gutter={[20, 20]}>
                    {companyItems &&
                      companyItems?.length > 0 &&
                      companyItems?.map((e) => (
                        <Col span={e?.col} key={e?.key}>
                          {e?.label}
                          <p>{e?.description}</p>
                        </Col>
                      ))}
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col flex={772} className="memberDetailRight">
          <div className="event_reservations">
            <h4>Event reservations</h4>
            <div className="event_filter">
              <div className="search_member_filter">
                <Input
                  placeholder="Search"
                  prefix={<SearchIcon />}
                  onChange={(e) =>
                    dispatch(
                      setEventDetailSearch(
                        "searchDetailMembers",
                        e.target.value
                      )
                    )
                  }
                />
              </div>
              <Select
                placeholder="Location"
                suffixIcon={<DropdownIcon />}
                onChange={(e) => dispatch(setEventDetailSearch("location", e))}
                popupClassName="location_popup"
                style={{ minWidth: "101px" }}
                options={citiesDropdown}
              />
              <Select
                placeholder="Type"
                suffixIcon={<DropdownIcon />}
                onChange={(e) => dispatch(setEventDetailSearch("type", e))}
                popupMatchSelectWidth={false}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {eventFilters?.type?.map(
                  (resp: { label: string; id: string }) => (
                    <Fragment key={resp?.id}>
                      <Option value={resp?.id} label={resp?.label}>
                        {resp?.label}
                      </Option>
                    </Fragment>
                  )
                )}
              </Select>
            </div>
          </div>

          <EventCards data={dataSource} />

          {(filterSearchData?.searchDetailMembers ||
            filterSearchData?.location ||
            filterSearchData?.type) &&
            dataSource?.length === 0 && (
              <div
                className="no_data_card"
                style={{ height: "calc(100vh - 200px)" }}
              >
                <div className="no_data_image">
                  <Image
                    src="/images/empty-Icon.svg"
                    preview={false}
                    width={216}
                    height={102}
                  />
                </div>
                <div className="no_data_content">
                  <h4>Nothing was found by search or filtering</h4>
                </div>
              </div>
            )}

          {(!memberDetailsData?.userEvents ||
            memberDetailsData?.userEvents?.length === 0) && (
            <div
              className="no_data_card"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <div className="no_data_image">
                <Image
                  src="/images/empty-Icon.svg"
                  preview={false}
                  width={216}
                  height={102}
                />
              </div>
              <div className="no_data_content">
                <h4>
                  This Member has no
                  <br /> event reservations
                </h4>
              </div>
            </div>
          )}

          {/* <div className="events_list">
            <h5>
              Past events <Tag>{pastEvents?.length}</Tag>
            </h5>
            {pastEvents &&
              pastEvents?.length > 0 &&
              pastEvents?.map((e) => (
                <EventCard
                  eventType={e?.eventType}
                  date={e?.date}
                  time={e?.time}
                  heading={e?.heading}
                  category={e?.category}
                  location={e?.location}
                  amount={e?.amount}
                  status={e?.status}
                />
              ))}
          </div> */}
        </Col>
      </Row>
      <Popup {...confirmDeleteMember} />
      <Popup {...confirmDeactivateMember} />
    </DashboardTemplate>
  );
}
