import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const setCancelationPolicy = (payload: {
  policyText: string;
}): Promise<AxiosResponse<{ policyText: string }>> => {
  return axios.post(`${baseURL}setCancelationPolicy`, payload);
};

export const addOperationTeamMember = (payload: {
  first_name: string;
  last_name: string;
  email: string;
  type: string;
}): Promise<
  AxiosResponse<{
    first_name: string;
    last_name: string;
    email: string;
    type: string;
  }>
> => {
  return axios.post(`${baseURL}addOperationTeamMember`, payload);
};

export const updateOperationTeamMember = (
  payload: {
    first_name: string;
    last_name: string;
    email: string;
    type: string;
  },
  memBerID: string
): Promise<
  AxiosResponse<{
    first_name: string;
    last_name: string;
    email: string;
    type: string;
  }>
> => {
  return axios.patch(`${baseURL}updateMemberProfile/${memBerID}`, payload);
};
