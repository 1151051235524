import {
  Row,
  Col,
  Descriptions,
  Tag,
  Avatar,
  Button,
  Space,
  TableColumnsType,
  Image,
  Card,
  Table,
  Dropdown,
  Breadcrumb,
  Badge,
  Input,
  Tooltip,
} from "antd";

import { useLocation, useNavigate } from "react-router-dom";
import type { DescriptionsProps, MenuProps } from "antd";
import DashboardTemplate from "../../../templates/Dashboard";
import {
  ArrowIconRight,
  BackArrowIcon,
  PlusIcon,
  SearchIcon,
  SortIcon,
} from "../../../atoms/CustomIcons";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { useEffect, useState } from "react";
import {
  getCompactUsersList,
  getEventById,
  setEventDetailSearch,
} from "../ducks/actions";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import {
  addEventAttendees,
  deleteEvent,
  removeEventAttendees,
  updateEventById,
} from "../ducks/services";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import { Popup } from "../../../atoms/Popup";
import AttendeesPopup from "../../../atoms/AttendeesPopup";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";

const { Meta } = Card;

interface DataType {
  key: React.Key;
  member: {
    text: string;
    image: string;
    email: string;
    id: string;
    type: string;
    first_name: string;
    short_location: string;
    position: string;
    company_name: string;
  };
  Position: string;
  Company: string;
  City: string;
}

export default function EventsDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const dispatch = useAppDispatch();
  const eventID = location?.pathname?.split("/")[2];
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [attendeePopup, setAttendeePopup] = useState<boolean>(false);
  const [badgeColor, setBadgeColor] = useState("");
  const [attendeeID, setattendeeID] = useState<string[]>([]);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [filteredHosts, setFilteredHosts] = useState<string[]>([]);
  const eventDetailsData = useAppSelector(
    (state) => state?.events?.eventDetail?.eventData
  );
  const eventLoader = useAppSelector(
    (state) => state?.events?.eventDetail?.loading
  );
  const filterSearchData = useAppSelector(
    (state) => state?.events?.filterSearch
  );
  const hostsList = useAppSelector((state) => state?.events?.hostsList);

  useEffect(() => {
    if (eventID) {
      dispatch(getEventById(eventID));
    }
  }, [dispatch, eventID]);

  useEffect(() => {
    dispatch(getCompactUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (hostsList?.length > 0 && eventDetailsData?.host_id) {
      const filterList = hostsList?.filter(
        (e: { id: string }) => e?.id !== eventDetailsData?.host_id
      );
      setFilteredHosts(filterList);
    }
  }, [hostsList, eventDetailsData]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Member",
      dataIndex: "member",
      sorter: (a, b) =>
        a?.member?.first_name?.localeCompare(b?.member?.first_name),
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <div className="table_with_image">
          {text?.files?.length > 0 ? (
            <Image
              src={text?.files[0]?.url}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
            />
          ) : (
            <Avatar shape="square" size={28} />
          )}
          <div className="table_text">
            <span className="main_text">{`${text?.first_name ?? ""} ${
              text?.last_name ?? ""
            }`}</span>
            <span className="email_text">{text?.email}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "member",
      render: (text) => text?.position ?? "-",
      sorter: (a, b) => a?.member?.position?.localeCompare(b?.member?.position),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Company",
      dataIndex: "member",
      render: (text) => text?.company_name ?? "-",
      sorter: (a, b) =>
        a?.member?.company_name?.localeCompare(b?.member?.company_name),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "City",
      dataIndex: "member",
      render: (text) => text?.short_location ?? "-",
      sorter: (a, b) =>
        a?.member?.short_location?.localeCompare(b?.member?.short_location),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "",
      width: 60,
      dataIndex: "action",
      render: (text, record) => (
        <Button
          type="text"
          onClick={() =>
            record?.member?.id &&
            navigate(
              record?.member?.type === "member"
                ? `/member-detail/${record?.member?.id}`
                : `/user-info/${record?.member?.id}`
            )
          }
        >
          <Image
            src="/images/right-arrow.svg"
            width={40}
            height={40}
            alt="Arrow"
            preview={false}
          />
        </Button>
      ),
    },
  ];

  const eventDetailsItems: DescriptionsProps["items"] = [
    {
      key: "1",
      label: (
        <p>
          <Image
            src="/images/members/topic.svg"
            alt="name"
            width={16}
            height={16}
            preview={false}
          />{" "}
          Topic
        </p>
      ),
      children: eventDetailsData?.topic,
    },
    {
      key: "2",
      label: (
        <p>
          <Image
            src="/images/members/desc.svg"
            alt="description"
            width={16}
            height={16}
            preview={false}
          />{" "}
          About this event
        </p>
      ),
      children: eventDetailsData?.about,
    },
    {
      key: "3",
      label: (
        <p>
          <Image
            src="/images/members/location.svg"
            alt="location"
            width={16}
            height={16}
            preview={false}
          />{" "}
          Location
        </p>
      ),
      children: (
        <p>
          {eventDetailsData?.city}
          {eventDetailsData?.venue && (
            <>
              <br />
              <span style={{ fontSize: 12 }}>{eventDetailsData?.venue}</span>
            </>
          )}
        </p>
      ),
    },
    {
      key: "4",
      label: (
        <p>
          <Image
            src="/images/members/fund.svg"
            alt="fund"
            width={16}
            height={16}
            preview={false}
          />{" "}
          Seat price
        </p>
      ),
      children: (
        <p>
          {eventDetailsData?.is_paid
            ? `$${eventDetailsData?.seat_price}`
            : "Free"}
          {eventDetailsData?.is_paid && (
            <>
              <br />
              <span style={{ fontSize: 12 }}>
                {eventDetailsData?.price_desc}
              </span>
            </>
          )}
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <p>
          <Image
            src="/images/members/money.svg"
            alt="Revenue"
            width={16}
            height={16}
            preview={false}
          />{" "}
          Minimum company revenue
        </p>
      ),
      children: eventDetailsData?.min_revenue,
    },
  ];

  const cancelledEvent = () => {
    setLoading(true);
    updateEventById({ status: "cancelled" }, eventID)
      .then(() => {
        setLoading(false);
        SuccessMessage("Event cancelled.");
        dispatch(getEventById(eventID));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const unpublishEvent = () => {
    setLoading(true);
    updateEventById({ status: "unpublished" }, eventID)
      .then(() => {
        setLoading(false);
        SuccessMessage("Event unpublished.");
        dispatch(getEventById(eventID));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const items: MenuProps["items"] =
    eventDetailsData?.status !== "cancelled"
      ? [
          {
            label: (
              <Link
                to={`/update-event/${eventDetailsData?.id}`}
                className="footer_dropdown"
              >
                <img src="/images/dropdown/edit.svg" alt="" /> Edit event
                details
              </Link>
            ),
            key: "1",
          },
          {
            label: (
              <Tooltip
                title={
                  dataSource?.length > 0 &&
                  eventDetailsData?.status === "published"
                    ? "It's not allowed when the event has attendees."
                    : null
                }
              >
                <Button
                  className="footer_logout publish_event"
                  onClick={() =>
                    eventDetailsData?.status === "unpublished"
                      ? publishEvent()
                      : unpublishEvent()
                  }
                  disabled={
                    dataSource?.length > 0 &&
                    eventDetailsData?.status === "published"
                  }
                >
                  <img src="/images/dropdown/unpublish.svg" alt="" />
                  {eventDetailsData?.status === "unpublished"
                    ? "Publish event"
                    : "Unpublish event"}
                </Button>
              </Tooltip>
            ),
            disabled:
              dataSource?.length > 0 &&
              eventDetailsData?.status === "published",
            key: "2",
          },
          {
            label: (
              <Button className="footer_logout" onClick={cancelledEvent}>
                <img src="/images/dropdown/close.svg" alt="" /> Cancel Event
              </Button>
            ),
            key: "3",
            disabled: eventDetailsData?.status === "unpublished",
          },
          {
            label: (
              <Button
                className="footer_logout"
                onClick={() => setConfirmDelete(true)}
              >
                <img src="/images/dropdown/delete.svg" alt="" /> Delete event
              </Button>
            ),
            key: "4",
          },
        ]
      : [
          {
            label: (
              <Button
                className="footer_logout"
                onClick={() => setConfirmDelete(true)}
              >
                <img src="/images/dropdown/delete.svg" alt="" /> Delete event
              </Button>
            ),
            key: "1",
          },
        ];

  const deleteFunction = () => {
    setLoading(true);
    deleteEvent(eventID)
      .then(() => {
        SuccessMessage("Event deleted.");
        setConfirmDelete(false);
        setLoading(false);
        navigate("/events");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const addingattendees = () => {
    setLoading(true);
    const payload = {
      eventId: eventID,
      attendeesIds: selectedItems,
    };
    addEventAttendees(payload)
      .then(() => {
        SuccessMessage("Attendees added");
        setSelectedItems([]);
        dispatch(getEventById(eventID));
        setAttendeePopup(false);
        setLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const confirmDeleteMember = {
    visibility: confirmDelete,
    class: "upload_photo_modal",
    content: (
      <ConfirmDelete
        title={
          <>
            <img src="/images/alert-icon.svg" alt="delete" /> Delete event
          </>
        }
        onClose={() => setConfirmDelete(false)}
        heading="Are you sure you want to delete this event?"
        paragraph="The event will be deleted. This action can't be undone."
        btnText="Delete event"
        btnApi={deleteFunction}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmDelete(false),
  };

  const addAttendee = {
    visibility: attendeePopup,
    class: "upload_photo_modal",
    content: (
      <AttendeesPopup
        title="Add attendees"
        onClose={() => setAttendeePopup(false)}
        btnText="Add attendees"
        hostsList={filteredHosts}
        btnApi={addingattendees}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        attendeeID={attendeeID}
        loading={loading}
        maxCount={eventDetailsData?.seat_allowance}
        listLength={eventDetailsData?.attendees?.length}
        dataSource={dataSource}
      />
    ),
    width: 520,
    onCancel: () => setAttendeePopup(false),
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const cancelbooking = () => {
    setLoading(true);
    const payload = {
      eventId: eventID,
      attendeesUserIds: selectedRowKeys,
    };
    removeEventAttendees(payload).then((e) => {
      SuccessMessage("Attendees removed.");
      dispatch(getEventById(eventID));
      setSelectedRowKeys([]);
      setAttendeePopup(false);
      setLoading(false);
    });
  };

  const publishEvent = () => {
    setLoading(true);
    updateEventById({ status: "published" }, eventID)
      .then(() => {
        setLoading(false);
        SuccessMessage("Event unpublished.");
        dispatch(getEventById(eventID));
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (eventDetailsData) {
      const availabilityPercentage =
        (eventDetailsData?.seat_occupied / eventDetailsData?.seat_allowance) *
        100;
      let clname = "";
      if (eventDetailsData?.seat_occupied === 0) {
        clname = "grey_badge";
      } else if (availabilityPercentage < 50) {
        clname = "yellow_badge";
      } else if (availabilityPercentage > 50) {
        clname = "green_badge";
      }
      setDataSource(eventDetailsData?.attendees);
      setBadgeColor(clname);

      const attendeeValue: string[] = [];
      eventDetailsData?.attendees?.map((e: { member: { id: string } }) => {
        attendeeValue.push(e?.member?.id);
        return attendeeValue;
      });
      setattendeeID(attendeeValue);
    }
  }, [eventDetailsData]);

  useEffect(() => {
    const currValue = filterSearchData?.searchDetailEvents?.toLowerCase();
    let filteredData = [];

    filteredData = eventDetailsData?.attendees?.filter((entry: any) => {
      const typedData = currValue
        ? (entry?.member?.company_name &&
            entry?.member?.company_name?.toLowerCase()?.includes(currValue)) ||
          (entry?.member?.last_name &&
            entry?.member?.last_name?.toLowerCase()?.includes(currValue)) ||
          (entry?.member?.first_name &&
            entry?.member?.first_name?.toLowerCase()?.includes(currValue)) ||
          (entry?.member?.short_location &&
            entry?.member?.short_location?.toLowerCase()?.includes(currValue))
        : true;

      return typedData;
    });
    setDataSource(filteredData);
  }, [filterSearchData]);

  return (
    <DashboardTemplate loading={eventLoader || loading}>
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <Breadcrumb
            separator={<ArrowIconRight />}
            items={[
              {
                href: "/events",
                title: (
                  <>
                    <BackArrowIcon /> Events
                  </>
                ),
              },
              {
                title: "Event details",
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <Card className="memberDetailCard">
            <Row gutter={24} justify="space-between" align="middle">
              <Col>
                <Meta
                  avatar={
                    <Avatar
                      src={
                        eventDetailsData?.files?.length > 0 &&
                        eventDetailsData?.files[0].url
                      }
                      size={70}
                      shape="square"
                    />
                  }
                  title={
                    <>
                      {eventDetailsData?.title}
                      {(eventDetailsData?.status === "unpublished" ||
                        eventDetailsData?.status === "published" ||
                        eventDetailsData?.status === "cancelled") && (
                        <Tag className={`${eventDetailsData?.status}_tag`}>
                          {eventDetailsData?.status}
                        </Tag>
                      )}
                    </>
                  }
                  description={
                    <>
                      {eventDetailsData?.date_time && (
                        <Tag className="event_datetime">
                          {dayjs(eventDetailsData?.date_time).format(
                            "MMM D, YYYY, hh:mm A"
                          )}
                        </Tag>
                      )}
                      {eventDetailsData?.type && (
                        <Tag className="event_stuff">
                          {eventDetailsData?.type}
                        </Tag>
                      )}
                    </>
                  }
                />
              </Col>
              <Col
                flex="0 0 250px"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Space size={8}>
                  {eventDetailsData?.status === "unpublished" && (
                    <Button className="icon_dark_btn" onClick={publishEvent}>
                      <Image src="/images/leadingIcon.svg" preview={false} />
                      Publish event
                    </Button>
                  )}
                  {eventDetailsData?.status !== "unpublished" &&
                    eventDetailsData?.conversation?.id && (
                      <div className="eventActiveChat">
                        <Image
                          src="/images/chatsCircle.svg"
                          alt="icon"
                          preview={false}
                          style={{ marginRight: "4px" }}
                        />
                        <span className="event_text">Event chat is active</span>
                      </div>
                    )}

                  <Dropdown
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayClassName="ft_dropdown"
                  >
                    <Button
                      type="text"
                      className="user_info_button"
                      onClick={(e) => e.preventDefault()}
                    >
                      <div className="user_info">
                        <Image
                          src={"/images/members/dots-icon.svg"}
                          alt="icon"
                          width={40}
                          height={40}
                          preview={false}
                        />
                      </div>
                    </Button>
                  </Dropdown>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="eventsLeftCard" flex="0 0 376px">
          <Row gutter={[12, 12]}>
            {/* <Col span={24}>
              <Card className="members_detail_left_card">
                <Descriptions
                  title="Event checklist"
                  items={personalItems}
                  layout="vertical"
                  column={1}
                />
              </Card>
            </Col> */}
            <Col span={24}>
              <Card className="members_detail_left_card">
                <h4 className="eventsDetailTitle"> Event details </h4>
                <Meta
                  className="events_host_detail"
                  avatar={
                    <Avatar
                      shape="square"
                      size={48}
                      src={
                        eventDetailsData?.host?.files?.length > 0 &&
                        eventDetailsData?.host?.files[0].url
                      }
                    />
                  }
                  title="Host"
                  description={
                    <>
                      {`${eventDetailsData?.host?.first_name ?? ""} ${
                        eventDetailsData?.host?.last_name ?? ""
                      }`}
                      {eventDetailsData?.host?.id && (
                        <div className="arrow_btn">
                          <Link
                            to={
                              eventDetailsData?.host?.type === "member"
                                ? `/member-detail/${eventDetailsData?.host?.id}`
                                : `/user-info/${eventDetailsData?.host?.id}`
                            }
                          >
                            <Image
                              src="/images/right-arrow.svg"
                              width={40}
                              height={40}
                              alt="Arrow"
                              preview={false}
                            />
                          </Link>
                        </div>
                      )}
                    </>
                  }
                />
                <Descriptions
                  // title="Event details"
                  items={eventDetailsItems}
                  layout="vertical"
                  column={1}
                />
              </Card>
            </Col>
          </Row>
        </Col>

        <Col className="eventsRightCard" flex={772}>
          <div className="event_reservations">
            <h4>
              Attendees list{" "}
              <Tag>
                <Badge className={`default_badge ${badgeColor}`} />
                {`${eventDetailsData?.seat_occupied ?? 0}/${
                  eventDetailsData?.seat_allowance ?? 0
                } seats`}
              </Tag>
            </h4>
            <div className="event_filter">
              <div className="search_filter">
                <Input
                  placeholder="Search"
                  prefix={<SearchIcon />}
                  onChange={(e) =>
                    dispatch(
                      setEventDetailSearch("searchDetailEvents", e.target.value)
                    )
                  }
                />
              </div>
              <div className="add_new_btn">
                <Button
                  className="icon_dark_btn"
                  disabled={
                    eventDetailsData?.status === "unpublished" ||
                    eventDetailsData?.status === "cancelled"
                  }
                  icon={<PlusIcon />}
                  onClick={() => setAttendeePopup(true)}
                >
                  Add Attendee
                </Button>
              </div>
            </div>
          </div>
          {dataSource?.length > 0 && (
            <Table
              rowSelection={rowSelection}
              className="dashboard_table"
              columns={columns}
              dataSource={dataSource}
              rowKey="user_id"
            />
          )}

          {filterSearchData?.searchDetailEvents?.length > 0 &&
            dataSource?.length === 0 && (
              <div
                className="no_data_card"
                style={{ height: "calc(100vh - 200px)" }}
              >
                <div className="no_data_image">
                  <Image
                    src="/images/empty-Icon.svg"
                    preview={false}
                    width={216}
                    height={102}
                  />
                </div>
                <div className="no_data_content">
                  <h4>Nothing was found by search</h4>
                </div>
              </div>
            )}

          {(!eventDetailsData?.attendees ||
            eventDetailsData?.attendees?.length === 0) && (
            <div
              className="no_data_card"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <div className="no_data_image">
                <Image
                  src="/images/empty-Icon.svg"
                  preview={false}
                  width={216}
                  height={102}
                />
              </div>
              <div className="no_data_content">
                <h4>Nobody has booked any seats yet.</h4>
              </div>
            </div>
          )}
        </Col>

        {selectedRowKeys?.length > 0 && (
          <Col span={24}>
            <div className="cancelBookingBar">
              <div className="selectedMember">
                {selectedRowKeys?.length === 1 && "1 member selected"}{" "}
                {selectedRowKeys?.length > 1 &&
                  `${selectedRowKeys?.length} members selected`}
              </div>
              <div className="eventsActionBtns">
                <Button
                  className="cancelBtn defaultBtnColor"
                  onClick={() => setSelectedRowKeys([])}
                >
                  Cancel
                </Button>
                <Button className="icon_dark_btn" onClick={cancelbooking}>
                  Cancel booking
                </Button>
              </div>
            </div>
          </Col>
        )}
      </Row>
      <Popup {...confirmDeleteMember} />
      <Popup {...addAttendee} />
    </DashboardTemplate>
  );
}
