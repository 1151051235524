import * as action_types from "./constants";

export type membersState = {
  filter: {
    status: undefined;
    search: undefined;
  };
  cancelationPolicy: {
    loading: boolean;
    policyData: {};
  };
};

const initialState: membersState = {
  cancelationPolicy: {
    loading: true,
    policyData: {},
  },
  filter: {
    status: undefined,
    search: undefined,
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.CANCELATION_POLICY:
      return {
        ...state,
        cancelationPolicy: { policyData: data, loading: action?.data?.loading },
      };

    case action_types.SET_FILTERS_OPTIONS_SYSTEM:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
      };

    default:
      return state;
  }
};
