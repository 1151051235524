import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  TableColumnsType,
  Tag,
  Button,
  Select,
  Radio,
  Input,
  Image,
  Badge,
  DatePicker,
  RadioChangeEvent,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import debounce from "lodash/debounce";
import DashboardTemplate from "../../templates/Dashboard";
import {
  DropdownIcon,
  PlusIcon,
  SearchIcon,
  SortIcon,
} from "../../atoms/CustomIcons";
import EmptyCard from "../../molecules/EmptyCard";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import {
  getEventFilters,
  getEventsList,
  setFiltersOptionsEvents,
} from "./ducks/actions";
import EmptyDataIcon from "../../atoms/CustomIcons/EmptyDataIcon";

const { Option } = Select;

export default function Members() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [filterBtn, setFilterBtn] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const eventsList = useAppSelector(
    (state) => state?.events?.eventsList?.listData
  );
  const eventsLoader = useAppSelector(
    (state) => state?.events?.eventsList?.loading
  );
  const eventFilters = useAppSelector((state) => state?.events?.eventFilters);
  const selectedFilters = useAppSelector(
    (state) => state?.events?.filterSelected
  );
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);

  interface DataType {
    key: React.Key;
    date_time: string;
    title: string;
    Host: string;
    type: string;
    seat_allowance: number;
    seat_occupied: number;
    seat_price: number;
    city: string;
    Price: string;
    status: string;
    files: { id: string; url: string; owner_id: string }[];
    id: string;
    is_paid: boolean;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: "Date",
      dataIndex: "date_time",
      render: (text) =>
        text ? dayjs(text).format("MMM D, YYYY, hh:mm A") : "-",
      sorter: (a, b) => a?.date_time?.localeCompare(b?.date_time),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a?.date_time?.localeCompare(b?.date_time),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Host",
      dataIndex: "Host",
      sorter: (a, b) => a?.Host?.localeCompare(b?.Host),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <Image
          src={
            record?.files?.length > 0
              ? record?.files[0].url
              : "/images/event-host.svg"
          }
          width={24}
          height={24}
          alt="Host"
          preview={false}
          style={{ borderRadius: 6 }}
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortIcon: () => <SortIcon />,
      render: (text) => <Tag className="table_type defaultTag">{text}</Tag>,
      width: 120,
    },
    {
      title: "Seats available",
      dataIndex: "seat_allowance",
      sorter: (a, b) => a.seat_allowance - b.seat_allowance,
      sortIcon: () => <SortIcon />,
      render: (text, record) => {
        let clname = "";
        const availabilityPercentage = (record?.seat_occupied / text) * 100;
        if (record?.seat_occupied === 0) {
          clname = "grey_badge";
        } else if (availabilityPercentage < 50) {
          clname = "yellow_badge";
        } else if (availabilityPercentage > 50) {
          clname = "green_badge";
        }
        return (
          <>
            <Badge className={`default_badge ${clname}`} />{" "}
            {record?.seat_occupied ?? 0}/{text}
          </>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: (a, b) => a?.city?.localeCompare(b?.city),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Price",
      dataIndex: "seat_price",
      sorter: (a, b) => a?.seat_price - b?.seat_price,
      sortIcon: () => <SortIcon />,
      render: (text, record) => (record?.is_paid ? `$${text}` : "Free"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 130,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let clname = "";
        if (text?.includes("cancelled")) {
          clname = "red_outline_tag";
        } else if (text?.includes("unpublished")) {
          clname = "unpublish_tag";
        } else {
          clname = "green_outline_tag";
        }
        return <Tag className={`defaultTag ${clname}`}>{text}</Tag>;
      },
    },
    {
      title: "",
      dataIndex: "action",
      width: 60,
      render: (text) => (
        <Image
          src="/images/right-arrow.svg"
          width={40}
          height={40}
          alt="Arrow"
          preview={false}
        />
      ),
    },
  ];

  const priceDropdown = [
    { value: "free", label: "Free" },
    { value: "paid", label: "Paid" },
  ];

  const onClickRow = (record: DataType) => {
    return {
      onClick: () => {
        navigate(`/event-detail/${record?.id}`);
      },
    };
  };

  // useEffect(() => {
  //   const currentTime = dayjs().toISOString();
  //   const payload = {
  //     offset: pagination?.current - 1,
  //     limit: pagination?.pageSize,
  //     isFuture: true,
  //     currentTime: currentTime,
  //     hideMyEvents: false,
  //   };
  //   dispatch(getEventsList(payload));
  // }, [pagination]);

  useEffect(() => {
    dispatch(getEventFilters());
  }, []);

  const handleSearch = useCallback(
    debounce((value) => {
      dispatch(setFiltersOptionsEvents("searchEvents", value));
    }, 500),
    []
  );

  const handleChange = (event: { target: { value: any } }) => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  };

  useEffect(() => {
    const currentTime = dayjs().toISOString();
    const payload = {
      offset: selectedFilters?.searchEvents
        ? 0
        : pagination > 1
        ? limit * (pagination - 1)
        : pagination - 1,
      limit: limit,
      isFuture: selectedFilters?.isFuture === "past" ? false : true,
      currentTime: currentTime,
      hideMyEvents: false,
      search: selectedFilters?.searchEvents
        ? selectedFilters?.searchEvents
        : undefined,
      host: selectedFilters?.host ? [selectedFilters?.host] : undefined,
      date: selectedFilters?.date
        ? dayjs(selectedFilters?.date).format("YYYY-MM-DD")
        : undefined,
      city: selectedFilters?.city ? [selectedFilters?.city] : undefined,
      type: selectedFilters?.type ? [selectedFilters?.type] : undefined,
      isPaid: selectedFilters?.price
        ? selectedFilters?.price === "paid"
          ? true
          : false
        : undefined,
      status: selectedFilters?.status ? [selectedFilters?.status] : undefined,
    };
    dispatch(getEventsList(payload));
  }, [selectedFilters, pagination, limit]);

  const resetFilters = () => {
    dispatch(setFiltersOptionsEvents("searchEvents", undefined));
    dispatch(setFiltersOptionsEvents("isFuture", undefined));
    dispatch(setFiltersOptionsEvents("host", undefined));
    dispatch(setFiltersOptionsEvents("type", undefined));
    dispatch(setFiltersOptionsEvents("city", undefined));
    dispatch(setFiltersOptionsEvents("date", undefined));
    dispatch(setFiltersOptionsEvents("price", undefined));
    dispatch(setFiltersOptionsEvents("status", undefined));
    setFilterBtn(false);
    setSearchValue("");
  };

  // useEffect(() => {
  //   resetFilters();
  // }, []);

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  return (
    <DashboardTemplate loading={eventsLoader}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="page_title">
            <h3>Events</h3>
          </div>
        </Col>
        <Col span={24} className="invoice_filter">
          <Row
            gutter={[16, 16]}
            justify="space-between"
            className="filter_btns"
          >
            <Col span={24}>
              <div className="top_filter">
                <div className="search_filter">
                  <Input
                    placeholder="Search events"
                    prefix={<SearchIcon />}
                    onChange={handleChange}
                    value={searchValue}
                  />
                </div>
                <div className="filter_right">
                  <div className="filter_btn">
                    <Button onClick={() => setFilterBtn(!filterBtn)}>
                      Filter
                      <Image
                        src="/images/filter-icon.svg"
                        alt="Filter"
                        width={16}
                        height={16}
                        preview={false}
                      />
                    </Button>
                  </div>
                  <div className="add_new_btn">
                    <Button
                      className="icon_dark_btn"
                      icon={<PlusIcon />}
                      onClick={() => navigate("/create-event")}
                    >
                      Create event
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
            {filterBtn && (
              <Col span={24}>
                <div className="filter_div">
                  <div className="filter_radio">
                    <Radio.Group
                      defaultValue="future"
                      onChange={({ target: { value } }: RadioChangeEvent) =>
                        dispatch(setFiltersOptionsEvents("isFuture", value))
                      }
                    >
                      <Radio.Button value="future">Future</Radio.Button>
                      <Radio.Button value="past">Past</Radio.Button>
                    </Radio.Group>
                  </div>
                  <div className="filter_search">
                    <Select
                      placeholder="Host"
                      suffixIcon={<DropdownIcon />}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsEvents("host", e))
                      }
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                    >
                      {eventFilters?.host?.map(
                        (resp: {
                          label: string;
                          id: string;
                          image: string;
                        }) => (
                          <Fragment key={resp?.id}>
                            <Option value={resp?.id} label={resp?.id}>
                              <Image
                                src={
                                  resp?.image
                                    ? resp?.image
                                    : "/images/event-host.svg"
                                }
                                width={28}
                                height={28}
                                alt="Host"
                                preview={false}
                                style={{ borderRadius: 6 }}
                              />{" "}
                              {resp?.label}
                            </Option>
                          </Fragment>
                        )
                      )}
                    </Select>
                    <Select
                      placeholder="City"
                      suffixIcon={<DropdownIcon />}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsEvents("city", e))
                      }
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                    >
                      {eventFilters?.cities?.map((resp: { name: string }) => (
                        <Fragment key={resp?.name}>
                          <Option value={resp?.name} label={resp?.name}>
                            {resp?.name}
                          </Option>
                        </Fragment>
                      ))}
                    </Select>
                    <Select
                      placeholder="Type"
                      suffixIcon={<DropdownIcon />}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsEvents("type", e))
                      }
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                    >
                      {eventFilters?.type?.map(
                        (resp: { label: string; id: string }) => (
                          <Fragment key={resp?.id}>
                            <Option value={resp?.id} label={resp?.label}>
                              {resp?.label}
                            </Option>
                          </Fragment>
                        )
                      )}
                    </Select>
                    <DatePicker
                      suffixIcon={<DropdownIcon />}
                      className="filter_date"
                      autoComplete="new-state"
                      format="MMM D, YYYY"
                      placeholder="Date & Time"
                      onChange={(_, str) =>
                        dispatch(setFiltersOptionsEvents("date", str))
                      }
                    />
                    <Select
                      placeholder="Seat Price"
                      suffixIcon={<DropdownIcon />}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsEvents("price", e))
                      }
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                    >
                      {priceDropdown?.map(
                        (resp: { label: string; value: string }) => (
                          <Fragment key={resp?.value}>
                            <Option value={resp} label={resp?.value}>
                              {resp?.label}
                            </Option>
                          </Fragment>
                        )
                      )}
                    </Select>
                    <Select
                      placeholder="Status"
                      suffixIcon={<DropdownIcon />}
                      onChange={(e) =>
                        dispatch(setFiltersOptionsEvents("status", e))
                      }
                      popupMatchSelectWidth={false}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      maxTagCount="responsive"
                    >
                      {eventFilters?.status?.map(
                        (resp: { label: string; id: string }) => (
                          <Fragment key={resp?.id}>
                            <Option value={resp?.id} label={resp?.id}>
                              {resp?.label}
                            </Option>
                          </Fragment>
                        )
                      )}
                    </Select>
                  </div>
                  <div className="reset_btn">
                    <Button onClick={resetFilters} type="text">
                      <Image
                        src="/images/close-icon-grey.svg"
                        alt="Close"
                        width={10}
                        height={10}
                        preview={false}
                      />{" "}
                      Reset Filters
                    </Button>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={24}>
          {eventsList?.data?.length > 0 && (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={eventsList?.data}
              onRow={onClickRow}
              pagination={{
                // showSizeChanger: membersList?.data?.length > limit,
                defaultPageSize: limit,
                total: eventsList?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              rowKey="id"
            />
          )}

          {!selectedFilters?.searchEvents && eventsList?.data?.length === 0 && (
            <EmptyCard
              image={<EmptyDataIcon />}
              title={
                selectedFilters?.isFuture === "past"
                  ? "There are no past events."
                  : "There are no events."
              }
              description='Click the "Create event" button to create an event.'
              buttonText="Create event"
              navigateTo="/create-event"
              showOnlyHeading={selectedFilters?.isFuture === "past"}
            />
          )}

          {selectedFilters?.searchEvents && eventsList?.data?.length === 0 && (
            <div
              className="no_data_card"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <div className="no_data_image">
                <Image
                  src="/images/empty-Icon.svg"
                  preview={false}
                  width={216}
                  height={102}
                />
              </div>
              <div className="no_data_content">
                <h4>No events found</h4>
              </div>
            </div>
          )}
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
