import { useForm } from "react-hook-form";
import { message, Image, Form, Button } from "antd";
import InputField from "../../molecules/FormItems/InputField";
import { forgotPassword } from "../Login/ducks/services";
import { AxiosError } from "axios";
import LoginTemplate from "../../templates/LoginTemplate";
import { useState } from "react";
import { CloseIcon, SuccessIcon } from "../../atoms/CustomIcons";
import SuccessMessage from "../../atoms/Toasts/SuccessMessage";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";

type FormValues = {
  email: string;
};

export default function ForgotPassword() {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<FormValues>({ mode: "onChange" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = (val: { email: string }) => {
    setLoading(true);
    forgotPassword(val)
      .then((res: any) => {
        if (res?.data?.success) {
          setLoading(false);
          SuccessMessage(
            res?.data?.message ?? "Reset Password instructions sent."
          );
          navigate("/login", { state: { id: 1, name: "reset" } });
        } else {
          setLoading(false);
          ErrorMessage(res?.data?.message ?? "Something went wrong");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  return (
    <LoginTemplate loading={loading}>
      <div className="loginForm">
        <div className="loginLogo">
          <Image
            src="/images/login/logo-avatar.svg"
            width={48}
            height={48}
            alt="Logo"
            preview={false}
          />
        </div>

        <h1> Forgot password? </h1>

        <p>
          Enter your account email address and we’ll send you instructions to
          how to reset the password.
        </p>

        <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <InputField
            fieldname="email"
            label="Account Email Address"
            control={control}
            className="requiredInput"
            initValue=""
            iProps={{
              placeholder: "Enter your email",
            }}
            isRequired
            rules={{
              required: "Account email address is required.",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Incorrect email address.",
              },
            }}
            validate={errors.email && "error"}
            validMessage={errors.email && errors.email.message}
          />
          <Button
            htmlType="submit"
            type="primary"
            className="icon_dark_btn"
            disabled={!isDirty || !isValid}
          >
            Reset password
          </Button>
        </Form>
      </div>
    </LoginTemplate>
  );
}
