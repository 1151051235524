import { Row, Col, Button, Card, Form, Spin } from "antd";
import { useForm } from "react-hook-form";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../molecules/FormItems/InputField";
import { useEffect, useState } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../config/hooks";
import {
  onImageDelete,
  updateAccountProfile,
  uploadUserImage,
} from "../../../organisms/MyAccount/ducks/services";
import { useAppDispatch } from "../../../config/hooks";
import { getUserDetails } from "../../../organisms/MyAccount/ducks/actions";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
};
const antIcon = <Loading3QuartersOutlined spin />;

export default function ChangePassword() {
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
    };
    updateAccountProfile(payload, accountData?.id)
      .then(() => {
        setLoading(false);
        dispatch(getUserDetails());
        SuccessMessage("Profile updated.");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (accountData) {
      setValue("first_name", accountData?.first_name);
      setValue("last_name", accountData?.last_name);
      setValue("email", accountData?.email);
    }
  }, [setValue, accountData]);

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    uploadUserImage({
      owner_id: accountData?.id,
      image: checklistFile?.uid ? checklistFile : undefined,
      image_type: "user_image",
    })
      .then((res) => {
        if (res) {
          SuccessMessage("Image changed.");
          dispatch(getUserDetails());
          setLoading(false);
          setUploadModal(false);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  function callUserDetailsAPI() {
    dispatch(getUserDetails());
  }

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Card className="leftTabCard">
        <div className="cardHead">
          <div className="cardHeadLeft">
            <h3>Account Details</h3>
            <p>View and update your account details.</p>
          </div>
        </div>
        <div className="cardContent">
          <Form onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4>Profile Photo</h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <UploadPhoto
                        uploadAvatar={
                          accountData?.files?.length > 0
                            ? accountData?.files[0].url
                            : "/images/upload-avatar.svg"
                        }
                        imageUploaded={accountData?.files?.length > 0}
                        uploadedImageURL={
                          accountData?.files?.length > 0
                            ? accountData?.files[0].url
                            : undefined
                        }
                        width={88}
                        height={88}
                        saveUpload={saveUpload}
                        loading={loading}
                        uploadModal={uploadModal}
                        setUploadModal={setUploadModal}
                        onImageDelete={() => {
                          onImageDelete(
                            setLoading,
                            SuccessMessage,
                            dispatch,
                            callUserDetailsAPI,
                            ErrorMessage,
                            accountData?.files.length > 0
                              ? accountData?.files[0]?.id
                              : undefined
                          );
                        }}
                        previewImage={previewImage}
                        checklistFile={checklistFile}
                        modalPreviewImage={modalPreviewImage}
                        setChecklistFile={setChecklistFile}
                        setPreviewImage={setPreviewImage}
                        setModalPreviewImage={setModalPreviewImage}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4>
                      First & Last Names <span className="red_color">*</span>
                    </h4>
                    <p style={{ color: "#484B53" }}>
                      Are visible on the platform to all its users.
                    </p>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <InputField
                        fieldname="first_name"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Enter First Name",
                        }}
                        isRequired
                        rules={{
                          required: "First name is required.",
                        }}
                        validate={errors.first_name && "error"}
                        validMessage={
                          errors.first_name && errors.first_name.message
                        }
                      />
                    </div>
                    <div className="leftCardDiv">
                      <InputField
                        fieldname="last_name"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Enter Last Name",
                        }}
                        isRequired
                        rules={{
                          required: "Last name is required.",
                        }}
                        validate={errors.last_name && "error"}
                        validMessage={
                          errors.last_name && errors.last_name.message
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4>
                      Email Address <span className="red_color">*</span>
                    </h4>
                    <p style={{ color: "#484B53" }}>
                      Is used to log in to the platform.
                    </p>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <InputField
                        fieldname="email"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Enter email address",
                        }}
                        isRequired
                        rules={{
                          required: "Email is required.",
                          pattern: {
                            value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: "Email is required.",
                          },
                        }}
                        validate={errors.email && "error"}
                        validMessage={errors.email && errors.email.message}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px" />
                  <Col>
                    <div className="leftCardDiv">
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </Spin>
  );
}
