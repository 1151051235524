import Icon from "@ant-design/icons";

const EmptyDataSvg = () => (
<svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.2" d="M31.75 20C31.75 21.3261 32.2768 22.5979 33.2145 23.5355C34.1521 24.4732 35.4239 25 36.75 25V30C36.75 30.3315 36.6183 30.6495 36.3839 30.8839C36.1495 31.1183 35.8315 31.25 35.5 31.25H15.5V8.75H35.5C35.8315 8.75 36.1495 8.8817 36.3839 9.11612C36.6183 9.35054 36.75 9.66848 36.75 10V15C35.4239 15 34.1521 15.5268 33.2145 16.4645C32.2768 17.4021 31.75 18.6739 31.75 20Z" fill="#A25EDC"/>
<path d="M36.75 16.25C37.0815 16.25 37.3995 16.1183 37.6339 15.8839C37.8683 15.6495 38 15.3315 38 15V10C38 9.33696 37.7366 8.70107 37.2678 8.23223C36.7989 7.76339 36.163 7.5 35.5 7.5H5.5C4.83696 7.5 4.20107 7.76339 3.73223 8.23223C3.26339 8.70107 3 9.33696 3 10V15C3 15.3315 3.1317 15.6495 3.36612 15.8839C3.60054 16.1183 3.91848 16.25 4.25 16.25C5.24456 16.25 6.19839 16.6451 6.90165 17.3483C7.60491 18.0516 8 19.0054 8 20C8 20.9946 7.60491 21.9484 6.90165 22.6517C6.19839 23.3549 5.24456 23.75 4.25 23.75C3.91848 23.75 3.60054 23.8817 3.36612 24.1161C3.1317 24.3505 3 24.6685 3 25V30C3 30.663 3.26339 31.2989 3.73223 31.7678C4.20107 32.2366 4.83696 32.5 5.5 32.5H35.5C36.163 32.5 36.7989 32.2366 37.2678 31.7678C37.7366 31.2989 38 30.663 38 30V25C38 24.6685 37.8683 24.3505 37.6339 24.1161C37.3995 23.8817 37.0815 23.75 36.75 23.75C35.7554 23.75 34.8016 23.3549 34.0983 22.6517C33.3951 21.9484 33 20.9946 33 20C33 19.0054 33.3951 18.0516 34.0983 17.3483C34.8016 16.6451 35.7554 16.25 36.75 16.25ZM5.5 26.125C6.91286 25.8381 8.18309 25.0716 9.09547 23.9553C10.0079 22.8391 10.5063 21.4417 10.5063 20C10.5063 18.5583 10.0079 17.1609 9.09547 16.0447C8.18309 14.9284 6.91286 14.1619 5.5 13.875V10H14.25V30H5.5V26.125ZM35.5 26.125V30H16.75V10H35.5V13.875C34.0871 14.1619 32.8169 14.9284 31.9045 16.0447C30.9921 17.1609 30.4937 18.5583 30.4937 20C30.4937 21.4417 30.9921 22.8391 31.9045 23.9553C32.8169 25.0716 34.0871 25.8381 35.5 26.125Z" fill="#A25EDC"/>
</svg>
);

const EmptyDataIcon = (props: any) => (
  <Icon component={EmptyDataSvg} {...props} />
);

export default EmptyDataIcon;