import { Button, message } from "antd";
import { CloseIcon, ErrorIcon } from "../../CustomIcons";

const ErrorMessage = (text: string) => {
  const myMessage = () => {
    message.error({
      key: "error",
      type: "error",
      content: (
        <div className="toast_wrapper">
          <div className="toast_text">{text}</div>
          <Button
            onClick={() => message.destroy("error")}
            icon={<CloseIcon />}
            className="close_icon"
          />
        </div>
      ),
      icon: <ErrorIcon />,
    });
  };

  return myMessage();
};

export default ErrorMessage;
