import { Image, Button, Space } from "antd";
import { Popup } from "../../../atoms/Popup";
import UploadPhotoPopup from "../../../atoms/UploadPhotoPopup";
import { useState } from "react";
import ConfirmDelete from "../../../atoms/ConfirmDelete";

type uploadProps = {
  uploadAvatar: string;
  width?: number;
  height?: number;
  loading: boolean;
  imageUploaded?: boolean;
  uploadedImageURL?: string | undefined;
  saveUpload: () => void;
  onImageDelete: () => void;
  uploadModal: boolean;
  setUploadModal: React.Dispatch<React.SetStateAction<boolean>>;
  checklistFile: any;
  modalPreviewImage: {
    file: string;
    imagePreviewUrl: any;
  };
  setChecklistFile: React.Dispatch<any>;
  setPreviewImage: React.Dispatch<
    React.SetStateAction<{
      file: string;
      imagePreviewUrl: any;
    }>
  >;
  setModalPreviewImage: React.Dispatch<
    React.SetStateAction<{
      file: string;
      imagePreviewUrl: any;
    }>
  >;
  previewImage: {
    file: string;
    imagePreviewUrl: any;
  };
};

const UploadPhoto = (props: uploadProps) => {
  const {
    uploadAvatar,
    width,
    height,
    imageUploaded,
    loading,
    saveUpload,
    uploadModal,
    setUploadModal,
    checklistFile,
    modalPreviewImage,
    uploadedImageURL,
    previewImage,
    setChecklistFile,
    setPreviewImage,
    setModalPreviewImage,
    onImageDelete,
  } = props;
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const uploadPhoto = {
    visibility: uploadModal,
    class: "upload_photo_modal",
    content: (
      <UploadPhotoPopup
        title="Upload Photo"
        onClose={() => setUploadModal(false)}
        checklistFile={checklistFile}
        modalPreviewImage={modalPreviewImage}
        uploadedImageURL={uploadedImageURL}
        imageUploaded={imageUploaded}
        setChecklistFile={setChecklistFile}
        setPreviewImage={setPreviewImage}
        setModalPreviewImage={setModalPreviewImage}
        saveUpload={saveUpload}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setUploadModal(false),
  };

  const deleteFunction = () => {
    if (onImageDelete) {
      onImageDelete();
    }
    setChecklistFile({});
    setPreviewImage({
      file: "",
      imagePreviewUrl: null,
    });
    setModalPreviewImage({
      file: "",
      imagePreviewUrl: null,
    });
    setConfirmDelete(false);
  };

  const confirmDeletePhoto = {
    visibility: confirmDelete,
    class: "upload_photo_modal",
    content: (
      <ConfirmDelete
        title={
          <>
            <img src="/images/alert-icon.svg" alt="delete" /> Delete Action
          </>
        }
        onClose={() => setConfirmDelete(false)}
        heading="Are you sure you want to delete your profile photo?"
        btnText="Delete photo"
        btnApi={deleteFunction}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmDelete(false),
  };

  return (
    <>
      <div className="location_photo">
        <div className="location_image">
          <div className="upload_avatar">
            <Image
              src={previewImage?.imagePreviewUrl ?? uploadAvatar}
              width={width}
              height={height}
              alt="Profile"
              preview={false}
            />
          </div>
          <div className="upload_btn">
            <Space size={12}>
              <Button
                onClick={() => setUploadModal(true)}
                className="icon_white_btn"
              >
                {!imageUploaded && !checklistFile?.name && (
                  <Image
                    src="/images/upload-icon.svg"
                    alt="Upload"
                    width={16}
                    height={16}
                    preview={false}
                  />
                )}
                {imageUploaded || checklistFile?.name
                  ? "Change Photo"
                  : "Upload Photo"}
              </Button>
              {(imageUploaded || checklistFile?.name) && (
                <Button
                  className="icon_white_btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    setConfirmDelete(true);
                  }}
                >
                  Delete photo
                </Button>
              )}
            </Space>
            <div className="format_text">JPG, PNG. 1 MB Max.</div>
          </div>
        </div>
      </div>

      <Popup {...uploadPhoto} />
      <Popup {...confirmDeletePhoto} />
    </>
  );
};

export default UploadPhoto;
