import { Modal } from 'antd';

export const Popup = (props) => {
  const { onCancel, content, title, visibility, width } = props;

  return (
    <Modal
      centered
      closable={false}
      wrapClassName={props.class}
      open={visibility}
      okButtonProps={{ className: 'ag-gray-button' }}
      cancelButtonProps={{ className: 'ag-gray-button-outline' }}
      title={title && title}
      footer={null}
      width={width}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      {content}
    </Modal>
  );
};
