import React from "react";
import { Image, Card, Tag } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

type EventCardProps = {
  eventType: string;
  date: string;
  time: string;
  heading: string;
  category: string;
  location: string;
  amount: boolean;
  status: string;
  seatPrice?: string;
  eventID?: string;
};

const EventCard = ({
  eventType,
  date,
  time,
  heading,
  category,
  location,
  amount,
  status,
  seatPrice,
  eventID,
}: EventCardProps) => {
  return (
    <Card className={`${eventType} eventCard`}>
      <div className="event_date">
        <span className="dateFld">{dayjs(date).format("MMM D, YYYY")}</span>
        <span className="timeFld">{dayjs(time).format("hh:mm A")}</span>
      </div>
      <div className="event_details">
        <h4>
          <Link to={`/event-detail/${eventID}`}>{heading}</Link>{" "}
          {status && status === "cancelled" && <Tag>{status}</Tag>}
        </h4>
        <div className="event_loc_cat">
          {category && <Tag>{category}</Tag>}
          {location && (
            <div className="event_location">
              <Image
                src="/images/location-icon.svg"
                alt="phone"
                width={20}
                height={20}
                preview={false}
              />{" "}
              {location}
            </div>
          )}
        </div>
      </div>
      <div className="event_amount">
        {amount ? (
          <>
            <span style={{ fontSize: 14, fontWeight: 500, fontFamily: "twklausannepan-Bold" }}>$</span>
            {seatPrice}
          </>
        ) : (
          "Free"
        )}
      </div>
    </Card>
  );
};

export default EventCard;
