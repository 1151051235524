import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import store from "../../../config/store";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const loading = (data: boolean) => {
  return {
    type: action_types.EVENT_DETAILS,
    data: { loading: data },
  };
};

export const loadingList = (data: boolean) => {
  return {
    type: action_types.EVENTS_LIST,
    data: { loading: data },
  };
};

export const getEventsList = (payload: { offset: number; limit: number }) => {
  store.dispatch(loadingList(true));
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getEventsList`, payload);
      dispatch({
        type: action_types.EVENTS_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.EVENTS_LIST,
        data: { loading: false },
      });
    }
  };
};

// export const getEventById = (id: string) => {
//   store.dispatch(loading(true));
//   return async (dispatch: Dispatch<Action>) => {
//     const { data } = await axios.get(`${baseURL}getEventById/${id}`);
//     dispatch({
//       type: action_types.EVENT_DETAILS,
//       data: { ...data, loading: false },
//     });
//   };
// };

export const getEventById = (id: string) => {
  store.dispatch(loading(true)); // Start loading

  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.get(`${baseURL}getEventById/${id}`);
      dispatch({
        type: action_types.EVENT_DETAILS,
        data: { ...data, loading: false },
      });
    } catch (error) {
      // Handle the error here
      console.error("Error fetching event data:", error);
      dispatch({
        type: action_types.EVENT_DETAILS,
        data: { loading: false },
      });
    }
  };
};

export const getCompactUsersList = () => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getCompactUsersList`);
    dispatch({
      type: action_types.HOST_LIST,
      data,
    });
  };
};

export const getEventFilters = () => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getEventFilters`);
    dispatch({
      type: action_types.EVENT_FILTER,
      data,
    });
  };
};

export const setFiltersOptionsEvents = (
  type: string,
  value: string | undefined | string[]
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS,
      payload: { filterSelected: { [type]: value } },
    });
  };
};

export const setEventDetailSearch = (
  type: string,
  value: string | undefined | string[]
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_FILTERS_SEARCH_OPTIONS,
      payload: { filterSearch: { [type]: value } },
    });
  };
};
