import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import store from "../../../config/store";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const loading = (data: boolean) => {
  return {
    type: action_types.CANCELATION_POLICY,
    data: { loading: data },
  };
};

export const getCancelationPolicy = () => {
  store.dispatch(loading(true));
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.get(`${baseURL}getCancelationPolicy`);
      dispatch({
        type: action_types.CANCELATION_POLICY,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.CANCELATION_POLICY,
        data: { loading: false },
      });
    }
  };
};

export const setFiltersOptionsSystem = (
  type: string,
  value: string | undefined
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS_SYSTEM,
      payload: { filter: { [type]: value } },
    });
  };
};
