import { Button, Image, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const { onClose, title, heading, paragraph, btnText, btnApi, loading } =
    props;

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={16} />
            </Button>
          </div>
        </div>

        <div className="modal_content">
          <h4>{heading}</h4>
          {paragraph && <p>{paragraph}</p>}
        </div>

        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn defaultBtnColor"
            type="default"
            size="large"
            onClick={() => onCloseHandler()}
          >
            Cancel
          </Button>

          <Button
            htmlType="button"
            className="actionBtn dangerBtnColor"
            type="primary"
            size="large"
            onClick={btnApi}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </Spin>
  );
};
