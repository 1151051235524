import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  Marker,
} from "@vis.gl/react-google-maps";
import MapHandler from "./map-handler";
import { AutocompleteCustom } from "./autocomplete-custom";
import { Button, Image } from "antd";

export default (props: any) => {
  const { onClose, title, selectedPlace, setSelectedPlace, lat, lng } = props;
  return (
    <>
      <div className="modal_header">
        <div className="title"> {title} </div>
        <div className="close_icon">
          <Button
            size="large"
            type="link"
            className="p-0 m-20 close_popup"
            onClick={() => onClose()}
          >
            <Image src="/images/close-icon.svg" preview={false} width={16} />
          </Button>
        </div>
      </div>

      <APIProvider apiKey={"AIzaSyDcQQw5LJ5YLntPe4kzR_8w-TKGumSFmfI"}>
        <Map
          style={{ width: "100%", height: "60vh" }}
          defaultZoom={3}
          defaultCenter={{ lat: lat, lng: lng }}
          gestureHandling={"greedy"}
          disableDefaultUI={true}
        >
          <Marker
            position={{
              lat: selectedPlace?.geometry?.location?.lat() ?? lat,
              lng: selectedPlace?.geometry?.location?.lng() ?? lng,
            }}
          />
        </Map>

        <MapControl position={ControlPosition.TOP_CENTER}>
          <div className="autocomplete-control">
            <AutocompleteCustom onPlaceSelect={setSelectedPlace} />
            <Button
              onClick={() => onClose()}
              className="icon_white_btn"
              type="primary"
              disabled={!selectedPlace?.geometry?.location?.lat()}
            >
              Save
            </Button>
          </div>
        </MapControl>

        <MapHandler place={selectedPlace} />
      </APIProvider>
    </>
  );
};
