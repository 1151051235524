import { Action, Dispatch } from "redux";
import axios from "../../../config/axiosInterceptor";
import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import * as action_types from "./constants";
import store from "../../../config/store";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const loading = (data: boolean) => {
  return {
    type: action_types.MEMBER_DETAILS,
    data: { loading: data },
  };
};

export const loadingList = (data: boolean) => {
  return {
    type: action_types.MENBERS_LIST,
    data: { loading: data },
  };
};

export const getMembersList = (payload: { offset: number; limit: number }) => {
  store.dispatch(loadingList(true));
  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.post(`${baseURL}getMemberList`, payload);
      dispatch({
        type: action_types.MENBERS_LIST,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.MENBERS_LIST,
        data: { loading: false },
      });
    }
  };
};

export const getCities = () => {
  return async (dispatch: Dispatch<Action>) => {
    const { data } = await axios.get(`${baseURL}getCities`);
    dispatch({
      type: action_types.GET_CITIES,
      data,
    });
  };
};

export const memberDetails = (id: string) => {
  store.dispatch(loading(true)); // Start loading

  return async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await axios.get(`${baseURL}memberDetails/${id}`);
      dispatch({
        type: action_types.MEMBER_DETAILS,
        data: { ...data, loading: false },
      });
    } catch (error) {
      dispatch({
        type: action_types.MEMBER_DETAILS,
        data: { loading: false },
      });
    }
  };
};

export const setFiltersOptionsMember = (
  type: string,
  value: string | undefined
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_FILTERS_OPTIONS,
      payload: { filter: { [type]: value } },
    });
  };
};

export const setEventDetailSearch = (
  type: string,
  value: string | undefined | string[]
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: action_types.SET_MEMBERS_SEARCH_OPTIONS,
      payload: { filterSearch: { [type]: value } },
    });
  };
};
