import Icon from "@ant-design/icons";

const DropdownSvg = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.07564 5.32588C3.30995 5.09157 3.68985 5.09157 3.92417 5.32588L7.9999 9.40162L12.0756 5.32588C12.31 5.09157 12.6899 5.09157 12.9242 5.32588C13.1585 5.5602 13.1585 5.9401 12.9242 6.17441L8.42417 10.6744C8.31165 10.7869 8.15903 10.8501 7.9999 10.8501C7.84077 10.8501 7.68816 10.7869 7.57564 10.6744L3.07564 6.17441C2.84132 5.9401 2.84132 5.5602 3.07564 5.32588Z"
      fill="#050622"
    />
  </svg>
);

const DropdownIcon = (props: any) => (
  <Icon component={DropdownSvg} {...props} />
);

export default DropdownIcon;
