import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ArrowSvg = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3537 9.85375L6.35372 14.8538C6.30727 14.9002 6.25212 14.9371 6.19142 14.9622C6.13072 14.9873 6.06567 15.0003 5.99997 15.0003C5.93428 15.0003 5.86922 14.9873 5.80852 14.9622C5.74783 14.9371 5.69268 14.9002 5.64622 14.8538C5.59977 14.8073 5.56292 14.7521 5.53778 14.6915C5.51263 14.6308 5.49969 14.5657 5.49969 14.5C5.49969 14.4343 5.51263 14.3693 5.53778 14.3086C5.56292 14.2479 5.59977 14.1927 5.64622 14.1463L10.2931 9.5L5.64622 4.85375C5.5524 4.75993 5.49969 4.63269 5.49969 4.5C5.49969 4.36732 5.5524 4.24007 5.64622 4.14625C5.74004 4.05243 5.86729 3.99973 5.99997 3.99973C6.13265 3.99973 6.2599 4.05243 6.35372 4.14625L11.3537 9.14625C11.4002 9.19269 11.4371 9.24783 11.4623 9.30853C11.4874 9.36923 11.5004 9.4343 11.5004 9.5C11.5004 9.56571 11.4874 9.63077 11.4623 9.69147C11.4371 9.75217 11.4002 9.80732 11.3537 9.85375Z"
      fill="#AEAFB2"
    />
  </svg>
);

const ArrowIconRight = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowSvg} {...props} />
);

export default ArrowIconRight;
