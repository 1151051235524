import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosError, AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";
import { Ilogin } from "./types";
import { RcFile } from "antd/es/upload";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const login = ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<AxiosResponse<Ilogin>> => {
  return axios.post(`${baseURL}login`, { email, password });
};

export const forgotPassword = ({
  email,
}: {
  email: string;
}): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.post(`${baseURL}forgotPassword`, { email });
};

export const changePassword = (payload: {
  userId: string;
  email: string;
  oldPassword: string;
  newPassword: string;
}): Promise<AxiosResponse<{ message: string; success: boolean }>> => {
  return axios.put(`${baseURL}changePassword`, payload);
};

export const uploadUserImage = async ({
  owner_id,
  image,
  image_type,
}: {
  owner_id: string;
  image: RcFile | File;
  image_type: string;
}): Promise<AxiosResponse<void>> => {
  const formData = new FormData();
  formData.append("owner_id", owner_id);
  formData.append("image", image);
  formData.append("image_type", image_type);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(`${baseURL}uploadUserImage`, formData, config);
};

export const updateAccountProfile = (
  payload: { first_name: string; last_name: string; email: string },
  memBerID: string
): Promise<
  AxiosResponse<{ first_name: string; last_name: string; email: string }>
> => {
  return axios.patch(`${baseURL}updateMemberProfile/${memBerID}`, payload);
};

export const deleteUserImage = (
  imageId: string
): Promise<
  AxiosResponse<{ first_name: string; last_name: string; email: string }>
> => {
  return axios.delete(`${baseURL}deleteFileById/${imageId}`);
};

export const onImageDelete = (
  setLoading: (state: boolean) => void,
  SuccessMessage: (text: string) => void,
  dispatch: (param: any) => void,
  refershCall: () => void,
  ErrorMessage: (err: string) => void,
  imageId?: string
) => {
  if (!imageId) {
    return;
  }
  setLoading(true);
  deleteUserImage(imageId)
    .then((res) => {
      if (res) {
        SuccessMessage("Image Deleted.");
        refershCall();
        setLoading(false);
      }
    })
    .catch((e: unknown) => {
      if (e instanceof AxiosError) {
        setLoading(false);
        ErrorMessage(
          e?.response?.data?.error?.message ?? "Something went wrong"
        );
      } else {
        setLoading(false);
        ErrorMessage("Something went wrong");
      }
    });
};
