/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { Form, Image, Select } from "antd";
import { Controller } from "react-hook-form";
import { InfoIconOutlined, SelectArrowIcon } from "../../../atoms/CustomIcons";

const { Option } = Select;

type OptionType = {
  first_name: string;
  last_name: string;
  id: string;
  files: { id: string; owner_id: string; url: string }[];
};
export interface SelectFieldProps {
  fieldname: string;
  isRequired?: boolean;
  label: string | null;
  validate?: any;
  validMessage?: any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  selectOption: Array<OptionType>;
  prefix?: any;
  hintMessage?: string;
  onSelectChange?: any;
  prefixIcon?: any;
  mode?: string;
  maxTagCount?: number;
  tooltipTitle?: string;
  optionalLabel?: string;
}

const HostSelectField: React.FunctionComponent<SelectFieldProps> = ({
  fieldname,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  iProps,
  selectOption,
  prefix,
  hintMessage,
  onSelectChange,
  prefixIcon,
  mode,
  maxTagCount,
  tooltipTitle,
  optionalLabel,
}: SelectFieldProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      label={label ?? null}
      validateStatus={validate}
      help={validMessage}
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoIconOutlined style={{ color: "#505560", fontSize: "14px" }} />
          ),
          placement: "topLeft",
          arrow: false,
        }
      }
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ?? null}
        rules={rules}
        render={({ field: { onChange, value, onBlur } }) => (
          <>
            {prefix && <div className="select_prefix">{prefix}</div>}
            {prefixIcon && (
              <div className="prefix_ixon_wrapper">{prefixIcon}</div>
            )}
            <Select
              suffixIcon={<SelectArrowIcon />}
              {...iProps}
              value={value ?? null}
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option?.label
                  ? option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  : option?.children?.toString()?.includes(input)
              }
              mode={mode}
              maxTagCount={maxTagCount}
              onChange={(e, k) => {
                onChange(e);
                onSelectChange && onSelectChange(k);
              }}
            >
              {selectOption?.map(
                (resp: {
                  first_name: string;
                  last_name: string;
                  id: string;
                  files: { id: string; owner_id: string; url: string }[];
                }) => (
                  <Fragment key={resp?.id}>
                    <Option value={resp?.id} label={resp?.first_name}>
                      <Image
                        src={
                          resp?.files?.length > 0
                            ? resp?.files[0].url
                            : "/images/event-host.svg"
                        }
                        width={24}
                        height={24}
                        alt="Host"
                        preview={false}
                        style={{ borderRadius: 6 }}
                      />
                      <span style={{ marginLeft: 8 }}>{`${
                        resp?.first_name ?? ""
                      } ${resp?.last_name ?? ""}`}</span>
                    </Option>
                  </Fragment>
                )
              )}
            </Select>
            {optionalLabel && <div className="optional-label">(Optional)</div>}
            {hintMessage && <div className="hint_text">{hintMessage}</div>}
          </>
        )}
      />
    </Form.Item>
  );
};

export default HostSelectField;
