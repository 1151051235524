import { Image, Spin } from "antd";
import { useEffect } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { isLoggedIn } from "../../config/utils";
import { useNavigate } from "react-router-dom";

const antIcon = <Loading3QuartersOutlined spin />;

type loginProps = {
  loading: boolean;
  children: React.ReactNode;
};

const LoginTemplate = (props: loginProps) => {
  const { loading, children } = props;
  const auth = isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      navigate("/");
    }
  }, [auth]);

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <main className="mainLogin">
        <div className="twoColumn">
          <div className="leftLogin">
            <div className="loginWrapper">{children}</div>
          </div>
          <div
            className="rightLogin"
            style={{
              backgroundImage: "url(/images/login/login-bg-vector.svg)",
            }}
          >
            {/* <div className="loginVector">
              <Image
                src="/images/login/login-vector.svg"
                alt="Login Hero"
                preview={false}
              />
            </div> */}
            <div className="right_wrapper">
              <div className="loginImage">
                <Image
                  src="/images/login/etable-login.png"
                  alt="Login Hero"
                  preview={false}
                />
              </div>
              <div className="quoteWrapper">
                <div className="quoteText">
                  <h3>
                    Those who think they can and those who think they can&apos;t
                    are both right.
                  </h3>
                  <p>
                    <i>Henry Ford</i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Spin>
  );
};

export default LoginTemplate;
