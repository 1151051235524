import { useEffect, useState } from "react";
import { Tag } from "antd";
import EventCard from "./Cards";

type EventCardProps = {
  data: [];
};

type userEvents = {
  type: string;
  date_time: string;
  status: string;
  title: string;
  topic: string;
  city: string;
  seat_price: string;
  id: string;
  is_paid: boolean;
  files: { id: string; owner_id: string; url: string };
};

const EventCards = ({ data }: EventCardProps) => {
  const [pastData, setPastData] = useState<[] | never[]>([]);
  const [upcomingData, setUpcomingData] = useState<[] | never[]>([]);
  const currentDate = new Date();

  useEffect(() => {
    if (data?.length > 0) {
      const pastDates = data?.filter(
        (event: { date_time: string }) =>
          new Date(event?.date_time) < currentDate
      );
      const upcomingDates = data?.filter(
        (event: { date_time: string }) =>
          new Date(event?.date_time) >= currentDate
      );
      if (pastDates && pastDates?.length > 0) {
        setPastData(pastDates);
      } else {
        setPastData([]);
      }
      if (upcomingDates && upcomingDates?.length > 0) {
        setUpcomingData(upcomingDates);
      } else {
        setUpcomingData([]);
      }
    } else {
      setPastData([]);
      setUpcomingData([]);
    }
  }, [data]);

  return (
    <>
      {upcomingData && upcomingData?.length > 0 && (
        <div className="event_card_div">
          <h5>
            Upcoming events <Tag>{upcomingData?.length}</Tag>
          </h5>
          {upcomingData?.map((e: userEvents) => (
            <EventCard
              eventType="upcoming"
              date={e?.date_time}
              time={e?.date_time}
              heading={e?.title}
              category={e?.type}
              location={e?.city}
              amount={e?.is_paid}
              seatPrice={e?.seat_price}
              status={e?.status}
              eventID={e?.id}
            />
          ))}
        </div>
      )}

      {pastData && pastData?.length > 0 && (
        <div className="event_card_div">
          <h5>
            Past events <Tag className="past_tag">{pastData?.length}</Tag>
          </h5>
          {pastData?.map((e: userEvents) => (
            <EventCard
              eventType="past"
              date={e?.date_time}
              time={e?.date_time}
              heading={e?.title}
              category={e?.type}
              location={e?.city}
              amount={e?.is_paid}
              seatPrice={e?.seat_price}
              status={e?.status}
              eventID={e?.id}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default EventCards;
