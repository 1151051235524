import * as action_types from "./constants";

export type accountState = {
  accountData: {};
};

const initialState: accountState = {
  accountData: {},
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.ACCOUNT_DATA:
      return { ...state, accountData: data };

    default:
      return state;
  }
};
