import Icon from "@ant-design/icons";

const DatePickerSvg = () => (

<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11 1H9.5V0.5C9.5 0.367392 9.44732 0.240215 9.35355 0.146447C9.25979 0.0526784 9.13261 0 9 0C8.86739 0 8.74021 0.0526784 8.64645 0.146447C8.55268 0.240215 8.5 0.367392 8.5 0.5V1H3.5V0.5C3.5 0.367392 3.44732 0.240215 3.35355 0.146447C3.25979 0.0526784 3.13261 0 3 0C2.86739 0 2.74021 0.0526784 2.64645 0.146447C2.55268 0.240215 2.5 0.367392 2.5 0.5V1H1C0.734784 1 0.48043 1.10536 0.292893 1.29289C0.105357 1.48043 0 1.73478 0 2V12C0 12.2652 0.105357 12.5196 0.292893 12.7071C0.48043 12.8946 0.734784 13 1 13H11C11.2652 13 11.5196 12.8946 11.7071 12.7071C11.8946 12.5196 12 12.2652 12 12V2C12 1.73478 11.8946 1.48043 11.7071 1.29289C11.5196 1.10536 11.2652 1 11 1ZM2.5 2V2.5C2.5 2.63261 2.55268 2.75979 2.64645 2.85355C2.74021 2.94732 2.86739 3 3 3C3.13261 3 3.25979 2.94732 3.35355 2.85355C3.44732 2.75979 3.5 2.63261 3.5 2.5V2H8.5V2.5C8.5 2.63261 8.55268 2.75979 8.64645 2.85355C8.74021 2.94732 8.86739 3 9 3C9.13261 3 9.25979 2.94732 9.35355 2.85355C9.44732 2.75979 9.5 2.63261 9.5 2.5V2H11V4H1V2H2.5ZM11 12H1V5H11V12ZM8.60375 6.64625C8.65024 6.69269 8.68712 6.74783 8.71228 6.80853C8.73744 6.86923 8.75039 6.93429 8.75039 7C8.75039 7.06571 8.73744 7.13077 8.71228 7.19147C8.68712 7.25217 8.65024 7.30731 8.60375 7.35375L5.60375 10.3538C5.55731 10.4002 5.50217 10.4371 5.44147 10.4623C5.38077 10.4874 5.31571 10.5004 5.25 10.5004C5.18429 10.5004 5.11923 10.4874 5.05853 10.4623C4.99783 10.4371 4.94269 10.4002 4.89625 10.3538L3.39625 8.85375C3.30243 8.75993 3.24972 8.63268 3.24972 8.5C3.24972 8.36732 3.30243 8.24007 3.39625 8.14625C3.49007 8.05243 3.61732 7.99972 3.75 7.99972C3.88268 7.99972 4.00993 8.05243 4.10375 8.14625L5.25 9.29313L7.89625 6.64625C7.94269 6.59976 7.99783 6.56288 8.05853 6.53772C8.11923 6.51256 8.18429 6.49961 8.25 6.49961C8.31571 6.49961 8.38077 6.51256 8.44147 6.53772C8.50217 6.56288 8.55731 6.59976 8.60375 6.64625Z" fill="#0D1019"/>
</svg>

);

const DatePickerIcon = (props: any) => (
  <Icon component={DatePickerSvg} {...props} />
);

export default DatePickerIcon;
