import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, Button, Form } from "antd";
import InputField from "../../../molecules/FormItems/InputField";
import FullPageLayout from "../../../templates/FullPageLayout";
import DateField from "../../../molecules/FormItems/DatePicker";
import InputNumberField from "../../../molecules/FormItems/InputNumber";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import SelectField from "../../../molecules/FormItems/SelectField";
import TextArea from "../../../molecules/FormItems/TextArea";
import RadioGroup from "../../../molecules/FormItems/RadioGroup";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getCities } from "../../Members/ducks/actions";
import dayjs from "dayjs";
import { addEvent } from "../ducks/services";
import { useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { uploadUserImage } from "../../MyAccount/ducks/services";
import { getCompactUsersList } from "../ducks/actions";
import HostSelectField from "../../../molecules/FormItems/HostSelectField";
import { Popup } from "../../../atoms/Popup";
import MapTemplate from "../../map";
import { CloseIcon } from "../../../atoms/CustomIcons";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { RangePickerProps } from "antd/es/date-picker";

export default function CreateEvents() {
  const {
    control,
    handleSubmit,
    watch,
    getValues,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [venueError, setVenueError] = useState<boolean>(false);
  const hostsList = useAppSelector((state) => state?.events?.hostsList);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const citiesList = useAppSelector((state) => state?.members?.citiesList);
  const [citiesDropdown, setCitiesDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const freePaid = watch("free_or_paid");

  const [selectedPlace, setSelectedPlace] =
    useState<google.maps.places.PlaceResult | null>(null);
  const [mapModal, setMapModal] = useState(false);

  const onSubmit = (val: any) => {
    if (!selectedPlace?.formatted_address) {
      setVenueError(true);
      return;
    }
    setLoading(true);
    const payload = {
      host_id: val?.host_id,
      title: val?.title,
      topic: val?.topic,
      date_time: dayjs(val?.date_time).toISOString(), // ISO 8601 format
      type: val?.type,
      seat_allowance: val?.seat_allowance,
      min_revenue: val?.min_revenue,
      venue: selectedPlace?.formatted_address,
      city: val?.city,
      is_paid: val?.free_or_paid === "paid",
      about: val?.about,
      status: "published",
      latitude: selectedPlace?.geometry?.location?.lat() ?? 0,
      longitude: selectedPlace?.geometry?.location?.lng() ?? 0,
      seat_price: val?.seat_price ?? undefined,
      price_desc: val?.price_desc ?? undefined,
    };

    addEvent(payload)
      .then((res: AxiosResponse) => {
        if (checklistFile?.uid && res && res?.data?.id) {
          uploadUserImage({
            owner_id: res?.data?.id,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "event_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("Event created.");
                setUploadModal(false);
                setLoading(false);
                navigate("/events");
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          SuccessMessage("Event created.");
          navigate("/events");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const radioData: Array<{ label: string; value: string }> = [
    { label: "Free", value: "free" },
    { label: "Paid", value: "paid" },
  ];

  const revenueDropdown = [
    { label: "$1 - 5M", value: "$1 - 5M" },
    { label: "$6 - 10M", value: "$6 - 10M" },
    { label: "$11 - 20M", value: "$11 - 20M" },
    { label: "$21 - 50M", value: "$21 - 50M" },
    { label: "$50M+", value: "$50M+" },
    { label: "Undisclosed", value: "Undisclosed" },
  ];

  const typeDropdown = [
    {
      value: "dinner",
      label: "Dinner",
    },
    {
      value: "conference",
      label: "Conference",
    },
    {
      value: "seminar",
      label: "Seminar",
    },
    {
      value: "coffee",
      label: "1:1 coffee",
    },
  ];

  useEffect(() => {
    dispatch(getCities());
    dispatch(getCompactUsersList());
  }, [dispatch]);

  useEffect(() => {
    if (citiesList?.length > 0) {
      let citiesArray: { label: string; value: string }[] = [];
      citiesList?.map((e: { name: string }) => {
        citiesArray.push({
          label: e?.name,
          value: e?.name,
        });
        return citiesArray;
      });
      setCitiesDropdown(citiesArray);
    }
  }, [citiesList]);

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    setLoading(false);
    setUploadModal(false);
  };

  const venueAddress = {
    visibility: mapModal,
    class: "venueAddressMap",
    content: (
      <MapTemplate
        title="Enter venue address"
        onClose={() => setMapModal(false)}
        selectedPlace={selectedPlace}
        setSelectedPlace={setSelectedPlace}
        loading={loading}
        lat={37.09024}
        lng={-95.712891}
      />
    ),
    width: 520,
    onCancel: () => setMapModal(false),
  };

  const saveDraft = () => {
    const val = getValues();
    trigger();
    if (!selectedPlace?.formatted_address) {
      setVenueError(true);
      return;
    }

    setLoading(true);
    const payload = {
      host_id: val?.host_id,
      title: val?.title,
      topic: val?.topic,
      date_time: dayjs(val?.date_time).toISOString(), // ISO 8601 format
      type: val?.type,
      seat_allowance: val?.seat_allowance,
      min_revenue: val?.min_revenue,
      venue: selectedPlace?.formatted_address,
      city: val?.city,
      is_paid: val?.free_or_paid === "paid",
      about: val?.about,
      status: "unpublished",
      latitude: selectedPlace?.geometry?.location?.lat() ?? 0,
      longitude: selectedPlace?.geometry?.location?.lng() ?? 0,
      seat_price: val?.seat_price ?? undefined,
      price_desc: val?.price_desc ?? undefined,
    };

    addEvent(payload)
      .then((res: AxiosResponse) => {
        if (checklistFile?.uid && res && res?.data?.id) {
          uploadUserImage({
            owner_id: res?.data?.id,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "event_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("Event created.");
                setUploadModal(false);
                setLoading(false);
                navigate("/events");
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          SuccessMessage("Event created.");
          navigate("/events");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().endOf("day");
  };

  return (
    <FullPageLayout loading={loading}>
      <div className="invoice_head">
        <div className="left_head">
          <h3>Create event</h3>
        </div>
      </div>
      <div className="page_center event_form">
        <div className="center_all">
          <div className="center_head">
            <div>
              <h3>Event details</h3>
              <p>Fill in the required information about the event.</p>
            </div>
          </div>

          <div className="divider"></div>

          <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
            <Row gutter={24}>
              <Col span={24}>
                <UploadPhoto
                  uploadAvatar="/images/members/event_thumbnail.svg"
                  width={343}
                  height={300}
                  saveUpload={saveUpload}
                  loading={loading}
                  uploadModal={uploadModal}
                  setUploadModal={setUploadModal}
                  onImageDelete={() => {}}
                  previewImage={previewImage}
                  checklistFile={checklistFile}
                  modalPreviewImage={modalPreviewImage}
                  setChecklistFile={setChecklistFile}
                  setPreviewImage={setPreviewImage}
                  setModalPreviewImage={setModalPreviewImage}
                />
              </Col>
              <Col span={12}>
                <InputField
                  fieldname="title"
                  label="Title"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Title",
                  }}
                  isRequired
                  rules={{
                    required: "Title is required.",
                  }}
                  validate={errors.title && "error"}
                  validMessage={errors.title && errors.title.message}
                />
              </Col>
              <Col span={24}>
                <InputField
                  fieldname="topic"
                  label="Topic"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Topic",
                  }}
                  isRequired
                  rules={{
                    required: "Topic is required.",
                  }}
                  validate={errors.topic && "error"}
                  validMessage={errors.topic && errors.topic.message}
                />
              </Col>
              <Col span={12}>
                <DateField
                  fieldname="date_time"
                  label="Date & time"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Select Date & time",
                    format: "MMM D, YYYY, hh:mm A",
                    showTime: true,
                    disabledDate: disabledDate,
                  }}
                  isRequired
                  rules={{
                    required: "Date & time is required.",
                  }}
                  validate={errors.date_time && "error"}
                  validMessage={errors.date_time && errors.date_time.message}
                />
              </Col>
              <Col span={12}>
                <HostSelectField
                  className="input_select"
                  fieldname="host_id"
                  label="Host"
                  control={control}
                  selectOption={hostsList}
                  isRequired={true}
                  rules={{ required: "Host is required." }}
                  iProps={{
                    placeholder: "Select Host",
                  }}
                  validate={errors.host_id && "error"}
                  validMessage={errors.host_id && errors.host_id.message}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="type"
                  label="Type"
                  control={control}
                  selectOption={typeDropdown}
                  isRequired={true}
                  rules={{ required: "Type is required." }}
                  iProps={{
                    placeholder: "Select Type",
                  }}
                  validate={errors.type && "error"}
                  validMessage={errors.type && errors.type.message}
                />
              </Col>
              <Col span={12}>
                <InputNumberField
                  fieldname="seat_allowance"
                  label="Seat allowance"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "0",
                  }}
                  isRequired
                  rules={{
                    required: "Seat allowance is required.",
                  }}
                  validate={errors.seat_allowance && "error"}
                  validMessage={
                    errors.seat_allowance && errors.seat_allowance.message
                  }
                  className="inputNumberFld"
                />
              </Col>
              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="min_revenue"
                  label="Minimum company revenue"
                  control={control}
                  selectOption={revenueDropdown}
                  isRequired={true}
                  rules={{ required: "Minimum company revenue is required." }}
                  iProps={{
                    placeholder: "Select Minimum company revenue",
                  }}
                  //tooltipTitle="Only members whose company's revenue is equal or higher can see and attend this event."
                  validate={errors.min_revenue && "error"}
                  validMessage={
                    errors.min_revenue && errors.min_revenue.message
                  }
                />
              </Col>
              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="city"
                  label="City"
                  control={control}
                  selectOption={citiesDropdown}
                  isRequired={true}
                  rules={{ required: "City is required." }}
                  iProps={{
                    placeholder: "Select City",
                  }}
                  validate={errors.city && "error"}
                  validMessage={errors.city && errors.city.message}
                />
              </Col>
              <Col span={24}>
                <div className="venue_address">
                  <h4>
                    Venue address <span>*</span>
                  </h4>
                  <Button
                    htmlType="button"
                    onClick={() => setMapModal(true)}
                    className={
                      selectedPlace?.formatted_address
                        ? "valueButton"
                        : venueError
                        ? "error_venue"
                        : ""
                    }
                  >
                    {selectedPlace
                      ? selectedPlace?.formatted_address
                      : "Enter venue address"}
                  </Button>
                  {venueError && (
                    <span style={{ color: "#ff4d4f" }}>
                      Venue address is required
                    </span>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <div className="fundingSource_radio">
                  <RadioGroup
                    fieldname="free_or_paid"
                    label="Specify if the entrance is free or paid"
                    control={control}
                    optionsData={radioData}
                    initValue="free"
                    onChangeRadio={() => {}}
                  />
                </div>
              </Col>
              {freePaid === "paid" && (
                <>
                  <Col span={12}>
                    <InputNumberField
                      fieldname="seat_price"
                      label="Seat Price"
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "0",
                      }}
                      isRequired
                      rules={{
                        required: "Seat Price is required.",
                      }}
                      validate={errors.seat_price && "error"}
                      validMessage={
                        errors.seat_price && errors.seat_price.message
                      }
                      className="inputNumberFld"
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      fieldname="price_desc"
                      label="Price description"
                      control={control}
                      initValue=""
                      iProps={{
                        placeholder: "Enter Price description",
                      }}
                      isRequired
                      rules={{
                        required: "Price description is required.",
                      }}
                      validate={errors.price_desc && "error"}
                      validMessage={
                        errors.price_desc && errors.price_desc.message
                      }
                    />
                  </Col>
                </>
              )}
              <Col span={24}>
                <TextArea
                  fieldname="about"
                  label="About this event"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder:
                      "Explain the members what to expect from this event",
                  }}
                  isRequired
                  rules={{
                    required: "About this event is required.",
                  }}
                  validate={errors.about && "error"}
                  validMessage={errors.about && errors.about.message}
                />
              </Col>
            </Row>
            <div className="discard_wrapper">
              <div className="discard_next_card">
                <div className="dn_left">
                  <Button
                    htmlType="button"
                    onClick={() => navigate(-1)}
                    type="text"
                    icon={<CloseIcon />}
                  >
                    Discard & Exit
                  </Button>
                </div>
                <div className="dn_right">
                  <Button
                    htmlType="button"
                    className="icon_white_btn"
                    onClick={() =>
                      !isDirty || !isValid ? trigger() : saveDraft()
                    }
                  >
                    Save as draft
                  </Button>
                  <Button htmlType="submit" className="icon_dark_btn">
                    Create & publish
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Popup {...venueAddress} />
    </FullPageLayout>
  );
}
