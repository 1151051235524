import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const SuccessSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9987 3.33325C6.32536 3.33325 3.33203 6.32659 3.33203 9.99992C3.33203 13.6733 6.32536 16.6666 9.9987 16.6666C13.672 16.6666 16.6654 13.6733 16.6654 9.99992C16.6654 6.32659 13.672 3.33325 9.9987 3.33325ZM13.1854 8.46659L9.40536 12.2466C9.31203 12.3399 9.18536 12.3933 9.05203 12.3933C8.9187 12.3933 8.79203 12.3399 8.6987 12.2466L6.81203 10.3599C6.6187 10.1666 6.6187 9.84659 6.81203 9.65325C7.00536 9.45992 7.32536 9.45992 7.5187 9.65325L9.05203 11.1866L12.4787 7.75992C12.672 7.56659 12.992 7.56659 13.1854 7.75992C13.3787 7.95325 13.3787 8.26659 13.1854 8.46659Z"
      fill="#18AD2E"
    />
  </svg>
);

const SuccessIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SuccessSvg} {...props} />
);

export default SuccessIcon;
