import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { Row, Col, Avatar, Button, Form, Card } from "antd";
import InputField from "../../../molecules/FormItems/InputField";
import FullPageLayout from "../../../templates/FullPageLayout";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import InputPhone from "../../../molecules/FormItems/InputPhone";
import SelectField from "../../../molecules/FormItems/SelectField";
import TextArea from "../../../molecules/FormItems/TextArea";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getCities, memberDetails } from "../ducks/actions";
import RadioGroup from "../../../molecules/FormItems/RadioGroup";
import { updateMemberProfile } from "../ducks/services";
import { AddMember } from "../ducks/types";
import BusinessBooks from "../Components/BusinessBooks";
import PersonalBooks from "../Components/PersonalBooks";
import { useLocation, useNavigate } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import { onImageDelete, uploadUserImage } from "../../MyAccount/ducks/services";
import { CloseIcon } from "../../../atoms/CustomIcons";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";

const { Meta } = Card;

export default function UpdateMembers() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const memberID = location?.pathname?.split("/")[2];
  const [loading, setLoading] = useState<boolean>(false);
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const citiesList = useAppSelector((state) => state?.members?.citiesList);
  const memberDetailsData = useAppSelector(
    (state) => state?.members?.memberDetails?.memberData
  );
  const [citiesDropdown, setCitiesDropdown] = useState<
    { label: string; value: string }[]
  >([]);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AddMember>({
    defaultValues: {
      fav_business_books: [
        {
          BusinessBooks: "",
        },
      ],
      fav_personal_books: [
        {
          PersonalBooks: "",
        },
      ],
    },
  });

  const {
    fields: businessFields,
    append: businessAppend,
    remove: businessRemove,
  } = useFieldArray({
    control,
    name: "fav_business_books",
  });
  const {
    fields: personalFields,
    append: personalAppend,
    remove: personalRemove,
  } = useFieldArray({
    control,
    name: "fav_personal_books",
  });

  const fundingSource = watch("company_funding_source");

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  useEffect(() => {
    if (citiesList?.length > 0) {
      let citiesArray: { label: string; value: string }[] = [];
      citiesList?.map((e: { name: string }) => {
        citiesArray.push({
          label: e?.name,
          value: e?.name,
        });
        return citiesArray;
      });
      setCitiesDropdown(citiesArray);
    }
  }, [citiesList]);

  useEffect(() => {
    if (memberID) {
      dispatch(memberDetails(memberID));
    }
  }, [dispatch, memberID]);

  useEffect(() => {
    if (memberDetailsData?.userData) {
      let businessBooksArray: { businessBook: string }[] = [];
      let personalBooksArray: { personalBooks: string }[] = [];

      if (memberDetailsData?.userData?.fav_business_books?.length > 0) {
        memberDetailsData?.userData?.fav_business_books?.map((e: any) =>
          businessBooksArray.push({ businessBook: e })
        );
      } else {
        businessBooksArray.push({ businessBook: "" });
      }

      if (memberDetailsData?.userData?.fav_personal_books?.length > 0) {
        memberDetailsData?.userData?.fav_personal_books?.map((e: any) =>
          personalBooksArray.push({ personalBooks: e })
        );
      } else {
        personalBooksArray.push({ personalBooks: "" });
      }

      setValue("first_name", memberDetailsData?.userData?.first_name);
      setValue("last_name", memberDetailsData?.userData?.last_name);
      setValue("email", memberDetailsData?.userData?.email);
      setValue("position", memberDetailsData?.userData?.position);
      setValue("phone", memberDetailsData?.userData?.phone);
      setValue("short_location", memberDetailsData?.userData?.short_location);
      setValue("fav_business_books", businessBooksArray);
      setValue("fav_personal_books", personalBooksArray);
      setValue("company_name", memberDetailsData?.userData?.company_name);
      setValue("company_website", memberDetailsData?.userData?.company_website);
      setValue("company_desc", memberDetailsData?.userData?.company_desc);
      setValue(
        "company_headquarter_location",
        memberDetailsData?.userData?.company_headquarter_location
      );
      setValue(
        "company_funding_source",
        memberDetailsData?.userData?.company_funding_source
      );
      setValue(
        "company_industry",
        memberDetailsData?.userData?.company_industry
      );
      setValue("company_revenue", memberDetailsData?.userData?.company_revenue);
      setValue(
        "company_amount_raised",
        memberDetailsData?.userData?.company_amount_raised !== "$0"
          ? memberDetailsData?.userData?.company_amount_raised
          : null
      );
    }
  }, [setValue, memberDetailsData?.userData]);

  const onSubmit = (val: AddMember) => {
    setLoading(true);
    let businessBooks: string[] = [];
    let personalBooks: string[] = [];
    val?.fav_business_books?.map((e: any) => {
      if (e?.businessBook) {
        return businessBooks.push(e?.businessBook);
      }
    });

    val?.fav_personal_books?.map((e: any) => {
      if (e?.personalBooks) {
        return personalBooks.push(e?.personalBooks);
      }
    });
    const payload = {
      fav_business_books: businessBooks,
      fav_personal_books: personalBooks,
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
      position: val?.position,
      phone: val?.phone,
      short_location: val?.short_location,
      company_name: val?.company_name,
      company_website: val?.company_website,
      company_desc: val?.company_desc,
      company_headquarter_location:
        val?.company_headquarter_location ?? undefined,
      company_industry: val?.company_industry ?? undefined,
      company_funding_source: val?.company_funding_source,
      company_revenue: val?.company_revenue,
      company_amount_raised:
        fundingSource === "vc_backed" ? val?.company_amount_raised : "$0",
    };
    updateMemberProfile(payload, memberID)
      .then((res: AxiosResponse) => {
        if (checklistFile?.uid && res) {
          uploadUserImage({
            owner_id: memberID,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "user_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("Member updated.");
                setUploadModal(false);
                setLoading(false);
                navigate("/members");
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          SuccessMessage("Member updated.");
          navigate("/members");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const positionDropdown = [
    { label: "CEO", value: "CEO" },
    { label: "CTO", value: "CTO" },
    { label: "COO", value: "COO" },
    { label: "CFO", value: "CFO" },
    { label: "Founder", value: "Founder" },
    { label: "Co-Founder", value: "CoFounder" },
  ];

  const industryDropdown = [
    { label: "Aerospace & Defense", value: "aerospacedefense" },
    { label: "Agriculture/Livestock", value: "agriculturelivestock" },
    { label: "Automotive", value: "automotive" },
    { label: "Business Services (Printing, etc.)", value: "businessservices" },
    { label: "Construction", value: "construction" },
    { label: "Consumer Services", value: "consumerservices" },
    { label: "Education Training", value: "educationtraining" },
    { label: "Private equity", value: "privateequity" },
  ];

  const revenueDropdown = [
    { label: "$1 - 5M", value: "$1 - 5M" },
    { label: "$6 - 10M", value: "$6 - 10M" },
    { label: "$11 - 20M", value: "$11 - 20M" },
    { label: "$21 - 50M", value: "$21 - 50M" },
    { label: "$50M+", value: "$50M+" },
    { label: "Undisclosed", value: "Undisclosed" },
  ];

  const radioData: Array<{ label: string; value: string }> = [
    { label: "Bootstrapped", value: "bootstrapped" },
    { label: "VC-backed / Raised capital", value: "vc_backed" },
  ];

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    setLoading(false);
    setUploadModal(false);
  };

  function callMembersAPI() {
    dispatch(memberDetails(memberID));
  }

  return (
    <FullPageLayout loading={loading}>
      <div className="invoice_head">
        <div className="left_head">
          <h3>Update member</h3>
        </div>
      </div>
      <div className="page_center member_form">
        <div className="center_all">
          <div className="center_head">
            <h3>Member profile details</h3>
            <p>Fill in the required information about the member.</p>
          </div>
          {/* <Card className="welcomeCard">
            <Meta
              avatar={<Avatar src="/images/info-icon.svg" />}
              title="Welcome email"
              description="Member will receive a welcome email with the account credentials after the account is created."
            />
          </Card> */}
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            className="members_form_area"
          >
            <Row gutter={24}>
              <Col span={24}>
                <h4>Personal details</h4>
                <UploadPhoto
                  uploadAvatar={
                    memberDetailsData?.userData?.files?.length > 0
                      ? memberDetailsData?.userData?.files[0].url
                      : "/images/upload-avatar.svg"
                  }
                  imageUploaded={memberDetailsData?.userData?.files?.length > 0}
                  uploadedImageURL={
                    memberDetailsData?.userData?.files?.length > 0
                      ? memberDetailsData?.userData?.files[0].url
                      : undefined
                  }
                  width={88}
                  height={88}
                  saveUpload={saveUpload}
                  onImageDelete={() => {
                    onImageDelete(
                      setLoading,
                      SuccessMessage,
                      dispatch,
                      callMembersAPI,
                      ErrorMessage,
                      memberDetailsData?.userData?.files?.length > 0
                        ? memberDetailsData.userData.files[0]?.id
                        : undefined
                    );
                  }}
                  loading={loading}
                  uploadModal={uploadModal}
                  setUploadModal={setUploadModal}
                  previewImage={previewImage}
                  checklistFile={checklistFile}
                  modalPreviewImage={modalPreviewImage}
                  setChecklistFile={setChecklistFile}
                  setPreviewImage={setPreviewImage}
                  setModalPreviewImage={setModalPreviewImage}
                />
              </Col>

              <Col span={12}>
                <InputField
                  fieldname="first_name"
                  label="First Name"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter First Name",
                  }}
                  isRequired
                  rules={{
                    required: "First name is required.",
                  }}
                  validate={errors.first_name && "error"}
                  validMessage={errors.first_name && errors.first_name.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  fieldname="last_name"
                  label="Last Name"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Last Name",
                  }}
                  isRequired
                  rules={{
                    required: "Last name is required.",
                  }}
                  validate={errors.last_name && "error"}
                  validMessage={errors.last_name && errors.last_name.message}
                />
              </Col>
              <Col span={12}>
                <InputField
                  fieldname="email"
                  label="Email"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter email address",
                    disabled: true,
                  }}
                  isRequired
                  rules={{
                    required: "Email is required.",
                    pattern: {
                      value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                      message: "Email is required.",
                    },
                  }}
                  validate={errors.email && "error"}
                  validMessage={errors.email && errors.email.message}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="position"
                  label="Position"
                  control={control}
                  selectOption={positionDropdown}
                  isRequired={true}
                  rules={{ required: "Position is required." }}
                  iProps={{
                    placeholder: "Select position",
                  }}
                  validate={errors.position && "error"}
                  validMessage={errors.position && errors.position.message}
                />
              </Col>
              <Col span={12}>
                <InputPhone
                  className="input_phone"
                  fieldname="phone"
                  label="Phone Number"
                  control={control}
                  initValue=""
                  tooltipTitle=""
                  iProps={{
                    placeholder: "Placeholder",
                  }}
                  // isRequired
                  // rules={{
                  //   required: "Error message",
                  // }}
                  // validate={errors.phone && "error"}
                  // validMessage={errors.phone && errors.phone.message}
                />
              </Col>
              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="short_location"
                  label="Location"
                  control={control}
                  selectOption={citiesDropdown}
                  isRequired={true}
                  rules={{ required: "Location is required." }}
                  iProps={{
                    placeholder: "Select city, country",
                  }}
                  validate={errors.short_location && "error"}
                  validMessage={
                    errors.short_location && errors.short_location.message
                  }
                />
              </Col>

              <div className="divider"> </div>

              <BusinessBooks
                control={control}
                append={businessAppend}
                remove={businessRemove}
                fields={businessFields}
                errors={errors}
              />

              <PersonalBooks
                control={control}
                append={personalAppend}
                remove={personalRemove}
                fields={personalFields}
                errors={errors}
              />

              <div className="divider"> </div>

              <Col span={24}>
                <h4> Company details </h4>
              </Col>

              <Col span={12}>
                <InputField
                  fieldname="company_name"
                  label="Name"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Company Name",
                  }}
                  isRequired
                  rules={{
                    required: "Company name is required.",
                  }}
                  validate={errors.company_name && "error"}
                  validMessage={
                    errors.company_name && errors.company_name.message
                  }
                />
              </Col>

              <Col span={12}>
                <InputField
                  fieldname="company_website"
                  label="Website"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Company Website",
                  }}
                  isRequired
                  rules={{
                    required: "Company website is required.",
                  }}
                  validate={errors.company_website && "error"}
                  validMessage={
                    errors.company_website && errors.company_website.message
                  }
                />
              </Col>

              <Col span={24}>
                <TextArea
                  fieldname="company_desc"
                  label="Description"
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter Company Description",
                  }}
                />
              </Col>

              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="company_headquarter_location"
                  label="Headquarters Location"
                  control={control}
                  selectOption={citiesDropdown}
                  iProps={{
                    placeholder: "Select city, country",
                  }}
                />
              </Col>

              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="company_industry"
                  label="Industry"
                  control={control}
                  selectOption={industryDropdown}
                  iProps={{
                    placeholder: "Select Industry",
                    placement: "topLeft",
                  }}
                />
              </Col>

              <Col span={12}>
                <div className="fundingSource_radio">
                  <RadioGroup
                    fieldname="company_funding_source"
                    label="Funding source"
                    control={control}
                    optionsData={radioData}
                    initValue="bootstrapped"
                    onChangeRadio={() => {}}
                    isRequired={true}
                    rules={{ required: "Funding source is required." }}
                    validate={errors.company_funding_source && "error"}
                    validMessage={
                      errors.company_funding_source &&
                      errors.company_funding_source.message
                    }
                  />
                </div>
              </Col>

              <Col span={12}>
                <SelectField
                  className="input_select"
                  fieldname="company_revenue"
                  label="Revenue"
                  control={control}
                  selectOption={revenueDropdown}
                  isRequired={true}
                  rules={{ required: "Revenue is required." }}
                  iProps={{
                    placeholder: "Select Revenue",
                    placement: "topLeft",
                  }}
                  validate={errors.company_revenue && "error"}
                  validMessage={
                    errors.company_revenue && errors.company_revenue.message
                  }
                />
              </Col>

              {fundingSource === "vc_backed" && (
                <Col span={12}>
                  <SelectField
                    className="input_select"
                    fieldname="company_amount_raised"
                    label="Amount raised"
                    control={control}
                    selectOption={revenueDropdown}
                    isRequired={true}
                    rules={{ required: "Amount raised is required." }}
                    iProps={{
                      placeholder: "Select Amount raised",
                    }}
                    validate={errors.company_amount_raised && "error"}
                    validMessage={
                      errors.company_amount_raised &&
                      errors.company_amount_raised.message
                    }
                  />
                </Col>
              )}
            </Row>

            <div className="discard_wrapper">
              <div className="discard_next_card">
                <div className="dn_left">
                  <Button
                    htmlType="button"
                    onClick={() => navigate("/members")}
                    type="text"
                    icon={<CloseIcon />}
                  >
                    Discard & Exit
                  </Button>
                </div>
                <div className="dn_right">
                  <Button htmlType="submit" className="icon_dark_btn">
                    Update member
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </FullPageLayout>
  );
}
