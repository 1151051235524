import React from "react";
import { Form, DatePicker } from "antd";
import { Controller } from "react-hook-form";
import { InfoIconOutlined, DatePickerIcon } from "../../../atoms/CustomIcons";

export interface InputFieldProps {
  fieldname: string;
  isRequired?: boolean;
  label?: string;
  validate?: any;
  validMessage?: string | any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  prefix?: any;
  disabled?: boolean;
  tooltipTitle?: string | any;
  optionalLabel?: string;
  hintMessage?: string;
}

const DateField: React.FunctionComponent<InputFieldProps> = ({
  fieldname,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  iProps,
  prefix,
  disabled,
  tooltipTitle,
  optionalLabel,
  hintMessage,
}: InputFieldProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      label={label}
      validateStatus={validate}
      help={validMessage}
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoIconOutlined style={{ color: "#505560", fontSize: "14px" }} />
          ),
        }
      }
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ?? ""}
        rules={rules}
        render={({ field: { onChange, value, onBlur } }) => (
          <>
            <DatePicker
              disabled={disabled && disabled}
              value={value}
              className="formControl"
              prefix={prefix}
              suffixIcon={<DatePickerIcon />}
              autoComplete="new-state"
              onChange={(e) => {
                onChange(e);
              }}
              format={[
                "MM/DD/YYYY",
                "MM-DD-YYYY",
                "MMDDYYYY",
                "DDMMYYYY",
                "DD/MM/YYYY",
                "DD-MM-YYYY",
                "MMM D YYYY",
                "M-D-YYYY",
                "M/D/YYYY",
                "D-M-YYYY",
                "D/M/YYYY",
                "MDYYYY",
                "DMYYYY",
              ]}
              {...iProps}
            />
            {optionalLabel && (
              <div className="optional-label">{optionalLabel}</div>
            )}
            {hintMessage && <div className="hint-label">{hintMessage}</div>}
          </>
        )}
      />
    </Form.Item>
  );
};

export default DateField;
