import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import "./app/assets/scss/style.scss";
import Login from "./app/organisms/Login";
import ForgotPassword from "./app/organisms/ForgotPassword";
import UpdatePassword from "./app/organisms/UpdatePassword";
import Members from "./app/organisms/Members";
import CreateMembers from "./app/organisms/Members/CreateMembers";
import MembersDetails from "./app/organisms/Members/MembersDetails";
import Events from "./app/organisms/Events";
import CreateEvents from "./app/organisms/Events/CreateEvents";
import EventsDetails from "./app/organisms/Events/EventsDetails";
import SystemSettings from "./app/organisms/SystemSettings";
import AddUser from "./app/organisms/SystemSettings/AddUser";
import MyAccount from "./app/organisms/MyAccount";
import { isLoggedIn } from "./app/config/utils";
import UpdateMembers from "./app/organisms/Members/UpdateMembers";
import UserInfo from "./app/organisms/SystemSettings/UserInfo";
import UpdateEvents from "./app/organisms/Events/UpdateEvents";
import Dashboard from "./app/organisms/Dashboard";
import NoPermission from "./app/organisms/NoPermission";

function App() {
  const auth = isLoggedIn();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "twklausannepan-Regular",
        },
      }}
    >
      <div className="App">
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/update-password" element={<UpdatePassword />} />
            {auth ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route path="/no-permission" element={<NoPermission />} />
                <Route path="/members" element={<Members />} />
                <Route path="/create-member" element={<CreateMembers />} />
                <Route path="/update-member/:id" element={<UpdateMembers />} />
                <Route path="/member-detail/:id" element={<MembersDetails />} />
                <Route path="/events" element={<Events />} />
                <Route path="/create-event" element={<CreateEvents />} />
                <Route path="/update-event/:id" element={<UpdateEvents />} />
                <Route path="/event-detail/:id" element={<EventsDetails />} />
                <Route path="/system-settings" element={<SystemSettings />} />
                <Route path="/add-user" element={<AddUser />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route path="/user-info/:id" element={<UserInfo />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to="/login" replace />} />
            )}
          </Routes>
        </Router>
      </div>
    </ConfigProvider>
  );
}

export default App;
