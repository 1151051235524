/* eslint-disable react/no-array-index-key */
import React, { Fragment } from "react";
import { Form, Select, Avatar, Tooltip, Tag } from "antd";
import { Controller } from "react-hook-form";
import { InfoIconOutlined, SelectArrowIcon } from "../../../atoms/CustomIcons";

const { Option } = Select;

type OptionType = {
  label?: string;
  value?: string | number;
};
export interface SelectFieldProps {
  fieldname: string;
  isRequired?: boolean;
  label: string | null;
  validate?: any;
  validMessage?: any;
  className?: string;
  control: any;
  initValue?: any;
  rules?: any;
  iProps?: any;
  selectOption: Array<OptionType>;
  prefix?: any;
  hintMessage?: string;
  onSelectChange?: any;
  prefixIcon?: any;
  mode?: string;
  maxTagCount?: number;
  tooltipTitle?: string;
  optionalLabel?: string;
}

const SelectField: React.FunctionComponent<SelectFieldProps> = ({
  fieldname,
  isRequired,
  label,
  validate,
  validMessage,
  className,
  control,
  initValue,
  rules,
  iProps,
  selectOption,
  prefix,
  hintMessage,
  onSelectChange,
  prefixIcon,
  mode,
  maxTagCount,
  tooltipTitle,
  optionalLabel,
}: SelectFieldProps) => {
  return (
    <Form.Item
      required={isRequired ?? false}
      label={label ?? null}
      validateStatus={validate}
      help={validMessage}
      className={className}
      tooltip={
        tooltipTitle && {
          title: tooltipTitle,
          icon: (
            <InfoIconOutlined style={{ color: "#505560", fontSize: "14px" }} />
          ),
          placement: "topLeft",
          arrow: false,
        }
      }
    >
      <Controller
        name={fieldname}
        control={control}
        defaultValue={initValue ?? null}
        rules={rules}
        render={({ field: { onChange, value, onBlur } }) => (
          <>
            {prefix && <div className="select_prefix">{prefix}</div>}
            {prefixIcon && (
              <div className="prefix_ixon_wrapper">{prefixIcon}</div>
            )}
            <Select
              suffixIcon={<SelectArrowIcon />}
              {...iProps}
              value={value ?? null}
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                option?.label
                  ? option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  : option?.children?.toString()?.includes(input)
              }
              mode={mode}
              maxTagCount={maxTagCount}
              onChange={(e, k) => {
                onChange(e);
                onSelectChange && onSelectChange(k);
              }}
              maxTagPlaceholder={
                <Tooltip
                  title={
                    mode === "multiple" &&
                    value?.length > 0 &&
                    value
                      ?.slice(maxTagCount)
                      ?.map((v: string) => <div key={v}>{v}</div>)
                  }
                >
                  +{maxTagCount && value?.length - maxTagCount}
                </Tooltip>
              }
            >
              {selectOption?.map((resp: any, i: number) => (
                <Fragment key={resp?.value + i}>
                  <Option value={resp?.value} label={resp?.label}>
                    <div className="option-label-item">
                      <div className="option_wrap">
                        {resp?.icon && (
                          <span
                            className="role_icon"
                            role="img"
                            aria-label={resp?.label}
                          >
                            <Avatar size={16} src={resp?.icon} />
                          </span>
                        )}
                        {resp?.avatar && (
                          <span
                            className="role_avatar"
                            role="img"
                            aria-label={resp?.label}
                          >
                            {resp?.avatar ? (
                              <Avatar size={24} src={resp?.avatar} />
                            ) : (
                              <Avatar size={24}>{resp?.initials}</Avatar>
                            )}
                          </span>
                        )}

                        <span className="role_label">{resp?.label}</span>
                        {resp?.tag &&
                          resp?.tag?.map((e: string) => <Tag key={e}>{e}</Tag>)}
                      </div>
                      {resp?.hint && (
                        <span className="hint_label">{resp?.hint}</span>
                      )}
                    </div>
                  </Option>
                </Fragment>
              ))}
            </Select>
            {optionalLabel && <div className="optional-label">(Optional)</div>}
            {hintMessage && <div className="hint_text">{hintMessage}</div>}
          </>
        )}
      />
    </Form.Item>
  );
};

export default SelectField;
