import { useEffect, useState } from "react";
import { Row, Col, Button, Spin, Card, Form } from "antd";
import { useForm } from "react-hook-form";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import TextAreaField from "../../../molecules/FormItems/TextArea";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getCancelationPolicy } from "../ducks/actions";
import { setCancelationPolicy } from "../ducks/services";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import { AxiosError } from "axios";

const antIcon = <Loading3QuartersOutlined spin />;

export default function CancelationPolicy() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const cancelationData = useAppSelector(
    (state) => state?.settings?.cancelationPolicy?.policyData
  );
  const cancelationLoader = useAppSelector(
    (state) => state?.settings?.cancelationPolicy?.loading
  );
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm();

  useEffect(() => {
    dispatch(getCancelationPolicy());
  }, [dispatch]);

  useEffect(() => {
    if (cancelationData?.value) {
      setValue("cancelation_policy_text", cancelationData?.value);
    }
  }, [setValue, cancelationData]);

  const onSubmit = (val: any) => {
    setLoading(true);
    const payload = {
      policyText: val?.cancelation_policy_text,
    };
    setCancelationPolicy(payload)
      .then(() => {
        SuccessMessage("Cancelation policy updated.");
        setLoading(false);
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  return (
    <Spin indicator={antIcon} spinning={loading || cancelationLoader}>
      <Card className="leftTabCard">
        <div className="cardHead">
          <div className="cardHeadLeft">
            <h3>Cancelation policy</h3>
            <p>This policy is automatically attached to all the events.</p>
          </div>
        </div>
        <div className="cardContent">
          <Form onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4>
                      Cancelation policy text
                      <span className="red_color">*</span>
                    </h4>
                  </Col>
                  <Col>
                    <div className="leftCardDiv cancelation_policy_text">
                      <TextAreaField
                        fieldname="cancelation_policy_text"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Enter Cancelation policy text",
                        }}
                        isRequired
                        rules={{
                          required: "Cancelation policy text is required.",
                        }}
                        validate={errors.cancelation_policy_text && "error"}
                        validMessage={
                          errors.cancelation_policy_text &&
                          errors.cancelation_policy_text.message
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px" />
                  <Col>
                    <div className="leftCardDiv">
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                        disabled={!isDirty || !isValid}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </Spin>
  );
}
