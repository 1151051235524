import { loading } from "../../Login/ducks/actions";
import * as action_types from "./constants";

export type eventState = {
  eventsList: {
    loading: boolean;
    listData: [];
  };
  hostsList: [];
  eventDetail: {
    loading: boolean;
    eventData: {};
  };
  eventFilters: {};
  filterSelected: {
    searchEvents: undefined;
    isFuture: undefined;
    host: undefined;
    type: undefined;
    date: undefined;
    price: undefined;
    status: undefined;
    city: undefined;
    eventSearch: undefined;
  };
  filterSearch: {
    searchDetailEvents: undefined;
  };
};

const initialState: eventState = {
  eventsList: {
    loading: true,
    listData: [],
  },
  hostsList: [],
  eventDetail: {
    loading: true,
    eventData: {},
  },
  eventFilters: {},
  filterSelected: {
    searchEvents: undefined,
    isFuture: undefined,
    host: undefined,
    type: undefined,
    date: undefined,
    price: undefined,
    status: undefined,
    city: undefined,
    eventSearch: undefined,
  },
  filterSearch: {
    searchDetailEvents: undefined,
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.EVENTS_LIST:
      return {
        ...state,
        eventsList: { listData: data, loading: action?.data?.loading },
      };

    case action_types.EVENT_DETAILS:
      return {
        ...state,
        eventDetail: { eventData: data, loading: action?.data?.loading },
      };

    case action_types.HOST_LIST:
      return { ...state, hostsList: data };

    case action_types.EVENT_FILTER:
      return { ...state, eventFilters: data };

    case action_types.SET_FILTERS_OPTIONS:
      return {
        ...state,
        filterSelected: {
          ...state.filterSelected,
          ...action.payload.filterSelected,
        },
      };

    case action_types.SET_FILTERS_SEARCH_OPTIONS:
      return {
        ...state,
        filterSearch: {
          ...state.filterSearch,
          ...action.payload.filterSearch,
        },
      };

    default:
      return state;
  }
};
