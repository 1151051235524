import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const ArrowSvg = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#E4E4E5" />
    <path
      d="M22.3538 24.6463C22.4002 24.6927 22.4371 24.7479 22.4622 24.8086C22.4874 24.8693 22.5003 24.9343 22.5003 25C22.5003 25.0657 22.4874 25.1308 22.4622 25.1915C22.4371 25.2521 22.4002 25.3073 22.3538 25.3538C22.3073 25.4002 22.2522 25.4371 22.1915 25.4622C22.1308 25.4873 22.0657 25.5003 22 25.5003C21.9343 25.5003 21.8693 25.4873 21.8086 25.4622C21.7479 25.4371 21.6927 25.4002 21.6463 25.3538L16.6463 20.3538C16.5998 20.3073 16.5629 20.2522 16.5377 20.1915C16.5126 20.1308 16.4996 20.0657 16.4996 20C16.4996 19.9343 16.5126 19.8692 16.5377 19.8085C16.5629 19.7478 16.5998 19.6927 16.6463 19.6463L21.6463 14.6463C21.7401 14.5524 21.8673 14.4997 22 14.4997C22.1327 14.4997 22.26 14.5524 22.3538 14.6463C22.4476 14.7401 22.5003 14.8673 22.5003 15C22.5003 15.1327 22.4476 15.2599 22.3538 15.3538L17.7069 20L22.3538 24.6463Z"
      fill="#0D1019"
    />
  </svg>
);

const BackArrowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowSvg} {...props} />
);

export default BackArrowIcon;
