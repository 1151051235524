import { Row, Col, Card, Form, Button, Breadcrumb } from "antd";
import { useForm } from "react-hook-form";
import DashboardTemplate from "../../../templates/Dashboard";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import { onImageDelete, uploadUserImage } from "../../MyAccount/ducks/services";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import UploadPhoto from "../../../molecules/FormItems/UploadPhoto";
import InputField from "../../../molecules/FormItems/InputField";
import SelectField from "../../../molecules/FormItems/SelectField";
import { memberDetails } from "../../Members/ducks/actions";
import { useLocation, useNavigate } from "react-router-dom";
import { updateOperationTeamMember } from "../ducks/services";
import { AxiosError, AxiosResponse } from "axios";
import { ArrowIconRight, BackArrowIcon } from "../../../atoms/CustomIcons";
// import ChangePassword from "../AddUser/";

type FormValues = {
  first_name: string;
  last_name: string;
  email: string;
  type: string;
};

const typeOptions = [
  { label: "Admin", value: "admin" },
  { label: "Manager", value: "manager" },
];

export default function SystemSettings() {
  const memberDetailsData = useAppSelector(
    (state) => state?.members?.memberDetails?.memberData
  );
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const memberID = location?.pathname?.split("/")[2];
  const [checklistFile, setChecklistFile] = useState<any>({});
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const [modalPreviewImage, setModalPreviewImage] = useState<{
    file: string;
    imagePreviewUrl: any;
  }>({ file: "", imagePreviewUrl: null });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit = (val: FormValues) => {
    setLoading(true);
    const payload = {
      first_name: val?.first_name,
      last_name: val?.last_name,
      email: val?.email,
      type: val?.type,
    };
    updateOperationTeamMember(payload, memberID)
      .then((res: AxiosResponse) => {
        if (checklistFile?.uid && res && res?.data?.id) {
          uploadUserImage({
            owner_id: res?.data?.id,
            image: checklistFile?.uid ? checklistFile : undefined,
            image_type: "user_image",
          })
            .then((res) => {
              if (res) {
                SuccessMessage("Changes saved.");
                setUploadModal(false);
                setLoading(false);
                navigate("/system-settings");
              }
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        } else {
          setLoading(false);
          SuccessMessage("Changes saved.");
          navigate("/system-settings");
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (memberDetailsData?.userData) {
      setValue("first_name", memberDetailsData?.userData?.first_name);
      setValue("last_name", memberDetailsData?.userData?.last_name);
      setValue("email", memberDetailsData?.userData?.email);
      setValue("type", memberDetailsData?.userData?.type);
    }
  }, [setValue, memberDetailsData?.userData]);

  const saveUpload = () => {
    setLoading(true);
    setPreviewImage({ ...modalPreviewImage });
    uploadUserImage({
      owner_id: memberDetailsData?.userData?.id,
      image: checklistFile?.uid ? checklistFile : undefined,
      image_type: "user_image",
    })
      .then((res) => {
        if (res) {
          SuccessMessage("Image changed.");
          setLoading(false);
          setUploadModal(false);
        }
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  useEffect(() => {
    if (memberID) {
      dispatch(memberDetails(memberID));
    }
  }, [dispatch, memberID]);

  function callMembersAPI() {
    dispatch(memberDetails(memberID));
  }

  return (
    <DashboardTemplate loading={loading}>
      <Row gutter={[24, 24]} className="user_info">
        <Col span={24}>
          <Breadcrumb
            separator={<ArrowIconRight />}
            items={[
              {
                href: "/system-settings",
                title: (
                  <>
                    <BackArrowIcon /> Settings
                  </>
                ),
              },
              {
                href: "/system-settings",
                title: <>Operations team</>,
              },
              {
                title: "User's info",
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <div className="page_title">
            <h3 style={{ marginBottom: "8px" }}> User's Info </h3>
            <p> View and update the account details. </p>
          </div>
        </Col>
        <Col span={24}>
          <Card className="leftTabCard">
            <div className="cardHead">
              <div className="cardHeadLeft">
                <h3>Account Details</h3>
                <p>View and update your account details.</p>
              </div>
            </div>
            <div className="cardContent">
              <Form onFinish={handleSubmit(onSubmit)}>
                <Row gutter={[0, 32]}>
                  <Col span={24}>
                    <Row gutter={21}>
                      <Col flex="0 0 273px">
                        <h4>Profile Photo</h4>
                      </Col>
                      <Col>
                        <div className="leftCardDiv">
                          <UploadPhoto
                            uploadAvatar={
                              memberDetailsData?.userData?.files?.length > 0
                                ? memberDetailsData?.userData?.files[0].url
                                : "/images/upload-avatar.svg"
                            }
                            imageUploaded={
                              memberDetailsData?.userData?.files?.length > 0
                            }
                            uploadedImageURL={
                              memberDetailsData?.userData?.files?.length > 0
                                ? memberDetailsData?.userData?.files[0].url
                                : undefined
                            }
                            width={88}
                            height={88}
                            saveUpload={saveUpload}
                            onImageDelete={() => {
                              onImageDelete(
                                setLoading,
                                SuccessMessage,
                                dispatch,
                                callMembersAPI,
                                ErrorMessage,
                                memberDetailsData?.userData?.files?.length > 0
                                  ? memberDetailsData?.userData?.files[0]?.id
                                  : undefined
                              );
                            }}
                            loading={loading}
                            uploadModal={uploadModal}
                            setUploadModal={setUploadModal}
                            previewImage={previewImage}
                            checklistFile={checklistFile}
                            modalPreviewImage={modalPreviewImage}
                            setChecklistFile={setChecklistFile}
                            setPreviewImage={setPreviewImage}
                            setModalPreviewImage={setModalPreviewImage}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={21}>
                      <Col flex="0 0 273px">
                        <h4>
                          First & Last Names <span>*</span>
                        </h4>
                        <p>Are visible on the platform to all its users.</p>
                      </Col>
                      <Col>
                        <div className="leftCardDiv">
                          <InputField
                            fieldname="first_name"
                            label=""
                            control={control}
                            initValue=""
                            iProps={{
                              placeholder: "Enter First Name",
                            }}
                            isRequired
                            rules={{
                              required: "First name is required.",
                            }}
                            validate={errors.first_name && "error"}
                            validMessage={
                              errors.first_name && errors.first_name.message
                            }
                          />
                        </div>
                        <div className="leftCardDiv">
                          <InputField
                            fieldname="last_name"
                            label=""
                            control={control}
                            initValue=""
                            iProps={{
                              placeholder: "Enter Last Name",
                            }}
                            isRequired
                            rules={{
                              required: "Last name is required.",
                            }}
                            validate={errors.last_name && "error"}
                            validMessage={
                              errors.last_name && errors.last_name.message
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={21}>
                      <Col flex="0 0 273px">
                        <h4>
                          Email Address <span>*</span>
                        </h4>
                        <p>Is used to log in to the platform.</p>
                      </Col>
                      <Col>
                        <div className="leftCardDiv">
                          <InputField
                            fieldname="email"
                            label=""
                            control={control}
                            initValue=""
                            iProps={{
                              placeholder: "Enter email address",
                            }}
                            isRequired
                            rules={{
                              required: "Email is required.",
                              pattern: {
                                value: /^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: "Email is required.",
                              },
                            }}
                            validate={errors.email && "error"}
                            validMessage={errors.email && errors.email.message}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={21}>
                      <Col flex="0 0 273px">
                        <h4>
                          Role <span>*</span>
                        </h4>
                      </Col>
                      <Col>
                        <div className="leftCardDiv">
                          <SelectField
                            className="input_select"
                            fieldname="type"
                            label=""
                            control={control}
                            selectOption={typeOptions}
                            isRequired={true}
                            rules={{ required: "Role is required" }}
                            iProps={{
                              placeholder: "Choose the role",
                              placement: "topLeft",
                            }}
                            validate={errors.type && "error"}
                            validMessage={errors.type && errors.type.message}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row gutter={21}>
                      <Col flex="0 0 273px" />
                      <Col>
                        <div className="leftCardDiv">
                          <Button
                            htmlType="submit"
                            type="primary"
                            className="icon_dark_btn"
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
