import { useEffect, useState } from "react";
import {
  DashboardIcon,
  InvoiceIcon,
  CoachesIcon,
  VerticalDots,
} from "../../atoms/CustomIcons";
import { Button, Layout, Menu, Image, Spin } from "antd";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../config/hooks";
import { getUserDetails } from "../../organisms/MyAccount/ducks/actions";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const { Sider, Content } = Layout;
const antIcon = <Loading3QuartersOutlined spin />;

type dashboardProps = {
  loading?: boolean;
  children?: React.ReactNode;
};

const DashboardTemplate = (props: dashboardProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const { loading, children } = props;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const accountData = useAppSelector((state) => state?.account?.accountData);

  const logoutHandler = () => {
    localStorage.clear();
    window.location.assign("/login");
  };

  const menuItems = [
    {
      key: "members",
      icon: <DashboardIcon />,
      label: <Link to="/members">Members</Link>,
    },
    {
      key: "events",
      icon: <InvoiceIcon />,
      label: <Link to="/events">Events</Link>,
    },
    {
      key: "system-settings",
      icon: <CoachesIcon />,
      label: <Link to="/system-settings">System settings</Link>,
    },
    // {
    //   key: "system-logs",
    //   icon: <CoachesIcon />,
    //   label: <Link to="/system-logs">System logs</Link>,
    // },
  ];

  const items: MenuProps["items"] = [
    {
      label: (
        <Link to="/my-account" className="footer_dropdown">
          <img src="/images/userIcon.svg" width={16} alt="" /> My Account{" "}
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to="/system-settings" className="footer_dropdown">
          <img src="/images/settingsIcon.svg" width={16} alt="" />{" "}
          Entrepreneur's Table Settings
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Button className="footer_logout main_logout" onClick={logoutHandler}>
          <img
            src="/images/logoutIcon.svg"
            width={22}
            alt=""
            style={{ marginLeft: -2 }}
          />
          Log Out
        </Button>
      ),
      key: "3",
    },
  ];

  useEffect(() => {
    if (
      accountData &&
      Object.keys(accountData)?.length > 0 &&
      accountData?.type === "member"
    ) {
      navigate("/");
    }
  }, [accountData]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const getSelectedMenuItemKey = () => {
    if (location.pathname.startsWith("/member-detail")) {
      return "members";
    }

    if (location.pathname.startsWith("/event-detail")) {
      return "events";
    }

    if (location.pathname.startsWith("/user-info")) {
      return "system-settings";
    }

    return location?.pathname?.split("/")[1]; // Extract the first path segment
  };

  const currentPath = getSelectedMenuItemKey();

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className="dashboard_sidebar"
          width={264}
        >
          <div className="sidebar_logo">
            <Link to="/dashboard" className="logo_area">
              <div className="logo_bg">
                <Image
                  src="/images/logo.svg"
                  width={189}
                  height={48}
                  alt="Logo"
                  preview={false}
                />
              </div>
            </Link>
          </div>
          <Menu
            mode="inline"
            className="sidebar_menu"
            items={menuItems}
            defaultSelectedKeys={[currentPath]}
            defaultOpenKeys={[currentPath]}
          />

          <div className="ant-layout-sider-trigger sidebar_footer">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="topRight"
              overlayClassName="ft_dropdown"
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className="user_info">
                  <div className="parent">
                    <div className="logo_bg">
                      {accountData?.files?.length > 0 && (
                        <Image
                          src={accountData?.files[0]?.url}
                          width={40}
                          height={40}
                          alt="Logo"
                          preview={false}
                        />
                      )}
                    </div>
                    <div className="logo_text">
                      <h4>
                        {`${accountData?.first_name ?? ""} ${
                          accountData?.last_name ?? ""
                        }`}{" "}
                      </h4>
                      <p> {accountData?.email ?? ""} </p>
                    </div>
                  </div>

                  <VerticalDots />
                </div>
              </a>
            </Dropdown>
          </div>
        </Sider>
        <Layout className="main_layout">
          <Content className="content_area">{children}</Content>
        </Layout>
      </Layout>
    </Spin>
  );
};

export default DashboardTemplate;
