import { useState } from "react";
import { Button, Col, Typography, Row } from "antd";
import InputField from "../../../../molecules/FormItems/InputField";
import { PlusIcon } from "../../../../atoms/CustomIcons";
import TrashIcon from "../../../../atoms/CustomIcons/TrashIcon";

const { Text } = Typography;

export default (props: any) => {
  const { control, append, remove, fields, errors } = props;

  const initQ: any = {
    personalBooks: "",
  };

  const onAdd = () => {
    append(initQ);
  };

  const deleteItems = (e: string, i: number) => {};

  const removeWeekTime = (ind: number) => {
    remove(ind);
  };

  return (
    <Col span={24}>
      <div className="tasks_section">
        <h4 className="heading"> Member's top personal books </h4>
        <p className="sub_heading">
          {" "}
          The favorite reads for personal growth and sheer joy.{" "}
        </p>
      </div>

      <Row
        align="bottom"
        gutter={[12, 0]}
        style={{ marginBottom: "25px", display: "flex", alignItems: "end" }}
      >
        <Col flex="0 0 347px">
          {fields.map((item: any, ind: number) => (
            <div className="addBook_wrapper" key={item.id}>
              <div className="book_name">
                <InputField
                  fieldname={`fav_personal_books.${ind}.personalBooks`}
                  control={control}
                  initValue=""
                  iProps={{
                    placeholder: "Enter book name",
                  }}
                  // isRequired
                  // rules={{
                  //   required: "Book name is required.",
                  // }}
                  // validMessage={
                  //   Object.entries(errors).length > 0 &&
                  //   errors?.fav_personal_books &&
                  //   errors?.fav_personal_books[ind]?.personalBooks &&
                  //   errors?.fav_personal_books?.length &&
                  //   errors?.fav_personal_books[ind]?.personalBooks?.message
                  // }
                  // validate={
                  //   Object.entries(errors).length > 0 &&
                  //   errors?.fav_personal_books &&
                  //   errors?.fav_personal_books?.length &&
                  //   errors?.fav_personal_books[ind]?.personalBooks &&
                  //   "error"
                  // }
                />
              </div>
              {ind > 0 && (
                <div className="book_delete">
                  <Button
                    htmlType="button"
                    className="removeFieldButton"
                    onClick={() => removeWeekTime(ind)}
                  >
                    <TrashIcon className="darkIcon" />
                  </Button>
                </div>
              )}
            </div>
          ))}
        </Col>
        <Col span={8}>
          <Button
            htmlType="button"
            onClick={onAdd}
            className="addFieldButton"
            icon={<PlusIcon className="darkIcon" />}
          >
            Add one more
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
