import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";
import { AddEvent } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const addEvent = (
  payload: AddEvent
): Promise<AxiosResponse<AddEvent>> => {
  return axios.post(`${baseURL}addEvent`, payload);
};

export const deleteEvent = (id: string): Promise<AxiosResponse<string>> => {
  return axios.delete(`${baseURL}events/${id}`);
};

export const addEventAttendees = (payload: {
  eventId: string;
  attendeesIds: string[];
}): Promise<AxiosResponse<{ eventId: string; attendeesIds: string[] }>> => {
  return axios.post(`${baseURL}addEventAttendees`, payload);
};

export const removeEventAttendees = (payload: {
  eventId: string;
  attendeesUserIds: React.Key[];
}): Promise<
  AxiosResponse<{ eventId: string; attendeesUserIds: React.Key[] }>
> => {
  return axios.post(`${baseURL}removeEventAttendees`, payload);
};

export const updateEventById = (
  payload: any,
  id: string
): Promise<AxiosResponse<{ payload: any; id: string }>> => {
  return axios.patch(`${baseURL}updateEventById/${id}`, payload);
};
