import { Button, Image, Spin, Select, Empty } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { SelectArrowIcon } from "../CustomIcons";
import { Fragment } from "react/jsx-runtime";

const { Option } = Select;
const antIcon = <Loading3QuartersOutlined spin />;

export default (props: any) => {
  const {
    onClose,
    title,
    hostsList,
    btnText,
    btnApi,
    loading,
    attendeeID,
    maxCount,
    selectedItems,
    setSelectedItems,
  } = props;

  const onCloseHandler = () => {
    setSelectedItems([]);
    onClose();
  };

  const handleChange = (value: string[]) => {
    setSelectedItems(value);
  };

  const suffix = (
    <>
      <span>
        {attendeeID?.length + selectedItems?.length} / {maxCount}
      </span>
      <SelectArrowIcon />
    </>
  );

  const filteredOptions = hostsList?.filter(
    (e: { id: string }) => !attendeeID.includes(e?.id)
  );

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <div className="newDailyChecklistForm photoUploader eTableModal">
        <div className="modal_header">
          <div className="title"> {title} </div>
          <div className="close_icon">
            <Button
              size="large"
              type="link"
              className="p-0 m-20 close_popup"
              onClick={() => onCloseHandler()}
            >
              <Image src="/images/close-icon.svg" preview={false} width={16} />
            </Button>
          </div>
        </div>

        <div className="modal_content attendee_modal">
          <h4>
            Attendees <span className="red_color">*</span>
          </h4>
          {attendeeID && (
            <Select
              placeholder="Select attendees"
              suffixIcon={suffix}
              popupMatchSelectWidth={false}
              getPopupContainer={(trigger) => trigger.parentNode}
              popupClassName="client_popup"
              showSearch
              className="add_attendee_select"
              optionFilterProp="children"
              mode="multiple"
              onChange={handleChange}
              maxCount={maxCount - attendeeID?.length}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No attendee/member found"
                />
              }
              filterOption={(input: any, option: any) =>
                option?.label
                  ? option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  : option?.children?.toString()?.includes(input)
              }
            >
              {filteredOptions?.map(
                (resp: {
                  first_name: string;
                  last_name: string;
                  id: string;
                  files: { id: string; owner_id: string; url: string }[];
                }) => (
                  <Fragment key={resp?.id}>
                    <Option
                      value={resp?.id}
                      label={`${resp?.first_name ?? ""} ${
                        resp?.last_name ?? ""
                      }`}
                    >
                      <Image
                        src={
                          resp?.files?.length > 0
                            ? resp?.files[0].url
                            : "/images/event-host.svg"
                        }
                        width={24}
                        height={24}
                        alt="Host"
                        preview={false}
                        style={{ borderRadius: 6 }}
                      />
                      <span style={{ marginLeft: 8 }}>{`${
                        resp?.first_name ?? ""
                      } ${resp?.last_name ?? ""}`}</span>
                    </Option>
                  </Fragment>
                )
              )}
            </Select>
          )}
        </div>

        <div className="modal_footer">
          <Button
            htmlType="button"
            className="cancelBtn defaultBtnColor"
            type="default"
            size="large"
            onClick={() => onCloseHandler()}
          >
            Cancel
          </Button>

          <Button
            htmlType="button"
            className="icon_dark_btn"
            type="primary"
            size="large"
            onClick={btnApi}
            disabled={selectedItems?.length === 0}
          >
            {btnText}
          </Button>
        </div>
      </div>
    </Spin>
  );
};
