import { useForm } from "react-hook-form";
import { Image, Form, Button, Alert } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputField from "../../molecules/FormItems/InputField";
import InputPassword from "../../molecules/FormItems/InputPassword";
import { login } from "./ducks/services";
import { AxiosError } from "axios";
import LoginTemplate from "../../templates/LoginTemplate";
import { useAppDispatch } from "../../config/hooks";
import { getUserDetails } from "../MyAccount/ducks/actions";
import { useState } from "react";
import { SuccessIcon } from "../../atoms/CustomIcons";
import ErrorMessage from "../../atoms/Toasts/ErrorMessage";

export default function Login() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const clearState = () => {
    navigate({ pathname: window.location.pathname }, { replace: true });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (val: any) => {
    setLoading(true);
    login(val)
      .then((res: any) => {
        if (res?.data?.success) {
          localStorage.setItem(
            "@etableToken",
            JSON.stringify(res?.data?.token)
          );

          dispatch(getUserDetails())
            .then(() => {
              window.location.assign("/");
              setLoading(false);
            })
            .catch((e: unknown) => {
              if (e instanceof AxiosError) {
                setLoading(false);
                ErrorMessage(
                  e?.response?.data?.error?.message ?? "Something went wrong"
                );
              } else {
                setLoading(false);
                ErrorMessage("Something went wrong");
              }
            });
        }
      })
      .catch((e: unknown) => {
        setLoading(false);
        if (e instanceof AxiosError) {
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          ErrorMessage("Something went wrong");
        }
      });
  };
  console.log("App Version 1.2.2");
  return (
    <LoginTemplate loading={loading}>
      <div className="loginForm">
        <div className="loginLogo login_mobile">
          <Image
            src="/images/login/logo-avatar.svg"
            width={48}
            height={48}
            alt="Logo"
            preview={false}
          />
        </div>

        <h1 className="login_mobile">
          Welcome to <br />
          Entrepreneurs’ Table
        </h1>

        <p className="login_mobile">Log in to your account.</p>

        {location?.state?.name === "reset" && (
          <div className="alertWrapper">
            <Alert
              message="A link to reset your password has been emailed to you."
              icon={<SuccessIcon />}
              type="success"
              onClose={clearState}
              showIcon
              closable
            />
          </div>
        )}

        {location?.state?.name === "update" && (
          <div className="alertWrapper login_mobile">
            <Alert
              message="Your password was reset. You can log in using your new password"
              icon={<SuccessIcon />}
              type="success"
              onClose={clearState}
              showIcon
              closable
            />
          </div>
        )}

        {location?.state?.name === "update" && (
          <div className="only_mobile center_login">
            <div className="alertWrapper">
              <Alert
                message={
                  <>
                    Your password was reset. <br />
                    <br />
                    Go back to your laptop or open the mobile app
                  </>
                }
                icon={<SuccessIcon />}
                type="success"
                onClose={clearState}
                showIcon
                closable
              />
            </div>

            <div className="open_app_btn">Open the app</div>
          </div>
        )}

        <Form
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
          className="login_mobile"
        >
          <InputField
            fieldname="email"
            label="Email Address"
            control={control}
            className="requiredInput"
            initValue=""
            iProps={{
              placeholder: "Enter your email",
            }}
            isRequired
            rules={{
              required: "Email is required.",
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Incorrect email address.",
              },
            }}
            validate={errors.email && "error"}
            validMessage={errors.email && errors.email.message}
          />
          <InputPassword
            fieldname="password"
            label="Password"
            control={control}
            className="requiredInput"
            initValue=""
            iProps={{
              placeholder: "Enter your password",
              autoComplete: "new-password",
            }}
            isRequired
            rules={{
              required: "Password is required.",
            }}
            validate={errors.password && "error"}
            validMessage={errors.password && errors.password.message}
          />
          <div className="forgotPass">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <Button
            htmlType="submit"
            type="primary"
            className="primaryButton loginBtn"
          >
            Log in
          </Button>
          <div className="cantLogin">
            <p>
              Can't log in? Please, contact our{" "}
              <a href="mailto:concierge@entrepreneurstable.com">support team</a>
            </p>
          </div>
        </Form>
      </div>
    </LoginTemplate>
  );
}
