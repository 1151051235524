import Icon from "@ant-design/icons";

const SearchSvg = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9422 17.058L14.0305 13.1471C15.1643 11.7859 15.7297 10.04 15.609 8.27263C15.4883 6.50524 14.6909 4.85241 13.3827 3.65797C12.0744 2.46353 10.356 1.81944 8.58498 1.85969C6.81394 1.89994 5.12659 2.62143 3.87395 3.87407C2.62131 5.12671 1.89982 6.81406 1.85957 8.5851C1.81932 10.3561 2.46341 12.0745 3.65785 13.3828C4.85229 14.691 6.50512 15.4884 8.2725 15.6091C10.0399 15.7298 11.7858 15.1644 13.1469 14.0306L17.0579 17.9424C17.1159 18.0004 17.1849 18.0465 17.2607 18.0779C17.3366 18.1094 17.4179 18.1255 17.5001 18.1255C17.5822 18.1255 17.6635 18.1094 17.7394 18.0779C17.8152 18.0465 17.8842 18.0004 17.9422 17.9424C18.0003 17.8843 18.0464 17.8154 18.0778 17.7395C18.1092 17.6636 18.1254 17.5823 18.1254 17.5002C18.1254 17.4181 18.1092 17.3367 18.0778 17.2609C18.0464 17.185 18.0003 17.1161 17.9422 17.058ZM3.12506 8.75018C3.12506 7.63766 3.45496 6.55012 4.07304 5.6251C4.69112 4.70007 5.56963 3.9791 6.59746 3.55336C7.6253 3.12761 8.7563 3.01622 9.84744 3.23326C10.9386 3.4503 11.9409 3.98603 12.7275 4.7727C13.5142 5.55937 14.0499 6.56165 14.267 7.6528C14.484 8.74394 14.3726 9.87494 13.9469 10.9028C13.5211 11.9306 12.8002 12.8091 11.8751 13.4272C10.9501 14.0453 9.86258 14.3752 8.75006 14.3752C7.25872 14.3735 5.82894 13.7804 4.77441 12.7258C3.71987 11.6713 3.12671 10.2415 3.12506 8.75018Z"
      fill="#0D1019"
    />
  </svg>
);

const SearchIcon = (props: any) => <Icon component={SearchSvg} {...props} />;

export default SearchIcon;
