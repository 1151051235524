import * as action_types from "./constants";

export type membersState = {
  membersList: {
    loading: boolean;
    listData: [];
  };
  citiesList: [];
  memberDetails: {
    loading: boolean;
    memberData: {};
  };
  filter: {
    searchMembers: undefined;
    location: undefined;
    industry: undefined;
    revenue: undefined;
    status: undefined;
  };
  filterSearch: {
    searchDetailMembers: undefined;
    location: undefined;
    type: undefined;
  };
};

const initialState: membersState = {
  membersList: {
    loading: true,
    listData: [],
  },
  citiesList: [],
  memberDetails: {
    loading: true,
    memberData: {},
  },
  filter: {
    searchMembers: undefined,
    location: undefined,
    industry: undefined,
    revenue: undefined,
    status: undefined,
  },
  filterSearch: {
    searchDetailMembers: undefined,
    location: undefined,
    type: undefined,
  },
};

export default (state = initialState, action: any) => {
  const { type, data } = action;
  switch (type) {
    case action_types.MENBERS_LIST:
      return {
        ...state,
        membersList: { listData: data, loading: action?.data?.loading },
      };

    case action_types.GET_CITIES:
      return { ...state, citiesList: data };

    case action_types.MEMBER_DETAILS:
      return {
        ...state,
        memberDetails: { memberData: data, loading: action?.data?.loading },
      };

    case action_types.SET_FILTERS_OPTIONS:
      return {
        ...state,
        filter: { ...state.filter, ...action.payload.filter },
      };

    case action_types.SET_MEMBERS_SEARCH_OPTIONS:
      return {
        ...state,
        filterSearch: {
          ...state.filterSearch,
          ...action.payload.filterSearch,
        },
      };

    default:
      return state;
  }
};
