import { Row, Col, Tabs } from "antd";
import type { TabsProps } from "antd";
import DashboardTemplate from "../../templates/Dashboard";
import AccountDetails from "./AccountDetails";
import ChangePassword from "./ChangePassword";

export default function SystemSettings() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Details",
      children: <AccountDetails />,
    },
    {
      key: "2",
      label: "Password",
      children: <ChangePassword />,
    },
  ];

  return (
    <DashboardTemplate loading={false}>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <div className="page_title">
            <h3>My Account</h3>
          </div>
        </Col>
        <Col span={24}>
          <Tabs defaultActiveKey="1" items={items} tabPosition="left" />
        </Col>
      </Row>
    </DashboardTemplate>
  );
}
