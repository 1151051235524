import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableColumnsType,
  Tag,
  Avatar,
  Button,
  Image,
  Card,
  Dropdown,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Input,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  PlusIcon,
  SearchIcon,
  SortIcon,
  VerticalDots,
} from "../../../atoms/CustomIcons";
import EmptyCard from "../../../molecules/EmptyCard";
import { useAppDispatch, useAppSelector } from "../../../config/hooks";
import { getMembersList } from "../../Members/ducks/actions";
import ConfirmDelete from "../../../atoms/ConfirmDelete";
import {
  deactivateMemberProfile,
  deleteMember,
} from "../../Members/ducks/services";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import { Popup } from "../../../atoms/Popup";
import { AxiosError } from "axios";
import debounce from "lodash/debounce";
import { setFiltersOptionsSystem } from "../ducks/actions";
import EmptyDataIcon from "../../../atoms/CustomIcons/EmptyDataIcon";
import { Loading3QuartersOutlined } from "@ant-design/icons";

interface DataType {
  key: React.Key;
  id: string;
  email: string;
  type: string;
  status: string;
  last_name: string;
  first_name: string;
  files: { id: string; url: string; owner_id: string }[];
}
const antIcon = <Loading3QuartersOutlined spin />;

export default function OperationsTeam() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tableRecord, setTableRecord] = useState<DataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmDeactivate, setConfirmDeactivate] = useState<boolean>(false);
  const [pagination, setPagination] = useState(1);
  const [limit, setLimit] = useState(10);
  const [trailData, setTrailData] = useState<boolean>(false);
  const [filterBtn, setFilterBtn] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const membersList = useAppSelector(
    (state) => state?.members?.membersList?.listData
  );
  const membersListLoading = useAppSelector(
    (state) => state?.members?.membersList?.loading
  );
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const selectedFilters = useAppSelector((state) => state?.settings?.filter);

  const teamDispatch = () => {
    const payload = {
      offset: 0,
      limit: 100,
      type: ["admin", "manager"],
    };
    dispatch(getMembersList(payload));
  };

  const deleteFunction = () => {
    setLoading(true);
    deleteMember(tableRecord?.id as string)
      .then(() => {
        SuccessMessage("User deleted.");
        setConfirmDelete(false);
        setLoading(false);
        teamDispatch();
        resetFilters();
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          if (e?.response?.data?.error?.statusCode === 409) {
            setTrailData(true);
            setConfirmDeactivate(true);
            setConfirmDelete(false);
          }
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const confirmDeleteMember = {
    visibility: confirmDelete,
    class: "upload_photo_modal",
    content: (
      <ConfirmDelete
        title={
          <>
            <img src="/images/alert-icon.svg" alt="delete" /> Delete user
          </>
        }
        onClose={() => setConfirmDelete(false)}
        heading={
          <>
            Are you sure you want to delete{" "}
            <b>
              {tableRecord?.first_name ?? ""} {tableRecord?.last_name ?? ""}
            </b>{" "}
            from the Entrepreneur's Table system?
          </>
        }
        paragraph="This user will be removed. This action can't be undone."
        btnText="Delete user"
        btnApi={deleteFunction}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmDelete(false),
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <Button
          className="footer_logout"
          onClick={() => navigate(`/user-info/${tableRecord?.id}`)}
        >
          <img src="/images/dropdown/edit.svg" alt="" /> Edit user’s info
        </Button>
      ),
      key: "1",
    },
    {
      label: (
        <Button
          className="footer_logout"
          onClick={() =>
            tableRecord?.status === "deactivated"
              ? activateMember()
              : setConfirmDeactivate(true)
          }
        >
          {tableRecord?.status === "deactivated" ? (
            <>
              <img src="/images/activate.svg" alt="" /> Activate user
            </>
          ) : (
            <>
              <img src="/images/deactivate.svg" alt="" /> Deactivate user
            </>
          )}
        </Button>
      ),
      key: "2",
    },
    {
      label: (
        <Button
          className="footer_logout"
          onClick={() => setConfirmDelete(true)}
        >
          <img src="/images/dropdown/delete.svg" alt="" />
          Delete user
        </Button>
      ),
      key: "3",
    },
  ];

  const columns: TableColumnsType<DataType> = [
    {
      title: "User",
      dataIndex: "first_name",
      sorter: (a, b) => a?.first_name?.localeCompare(b?.first_name),
      sortIcon: () => <SortIcon />,
      render: (text, record) => (
        <div className="table_with_image">
          {record?.files?.length > 0 ? (
            <Image
              src={record?.files[0].url}
              width={28}
              height={28}
              alt="Quote"
              preview={false}
            />
          ) : (
            <Avatar size={28} shape="square" />
          )}
          <div className="table_text">
            <span className="main_text">{`${text ?? ""} ${
              record?.last_name
            }`}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortIcon: () => <SortIcon />,
    },
    {
      title: "Role",
      dataIndex: "type",
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortIcon: () => <SortIcon />,
      render: (text) => (
        <span style={{ textTransform: "capitalize" }}>
          {text === "admin" ? "Super Admin" : "Community Manager"}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 130,
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      sortIcon: () => <SortIcon />,
      render: (text) => {
        let clname = "";
        if (text?.includes("active")) {
          clname = "green_outline_tag";
        } else {
          clname = "red_outline_tag";
        }
        return <Tag className={`defaultTag ${clname}`}>{text}</Tag>;
      },
    },
    {
      dataIndex: "action",
      width: 60,
      render: (text, record) => {
        return (
          accountData?.type === "admin" && (
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement="topRight"
              overlayClassName="ft_dropdown"
            >
              <Button
                onClick={() => setTableRecord(record)}
                className="table_dropdown"
              >
                <VerticalDots />
              </Button>
            </Dropdown>
          )
        );
      },
    },
  ];

  // useEffect(() => {
  //   const payload = {
  //     offset: 0,
  //     limit: 100,
  //     type: ["admin", "manager"],
  //   };
  //   dispatch(getMembersList(payload));
  // }, [dispatch]);

  const deactivateFunction = () => {
    setLoading(true);
    const payload = {
      status: "deactivated",
    };
    deactivateMemberProfile(payload, tableRecord?.id as string)
      .then(() => {
        SuccessMessage("User deactivated.");
        setConfirmDeactivate(false);
        setConfirmDelete(false);
        setLoading(false);
        teamDispatch();
        resetFilters();
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          setTrailData(false);
          setConfirmDeactivate(false);
          setConfirmDelete(false);
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const activateMember = () => {
    setLoading(true);
    const payload = {
      status: "active",
    };
    deactivateMemberProfile(payload, tableRecord?.id as string)
      .then(() => {
        SuccessMessage("User activated.");
        setConfirmDelete(false);
        setLoading(false);
        teamDispatch();
        resetFilters();
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
          setTrailData(false);
          setConfirmDeactivate(false);
          setConfirmDelete(false);
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  const confirmDeactivateMember = {
    visibility: confirmDeactivate,
    class: "upload_photo_modal",
    content: (
      <ConfirmDelete
        title={
          <>
            <img src="/images/alert-icon.svg" alt="delete" />{" "}
            {trailData ? "Delete user" : "Deactivate user"}
          </>
        }
        onClose={() => setConfirmDeactivate(false)}
        heading={
          trailData ? (
            "The user can be deactivated only"
          ) : (
            <>
              Are you sure you want to deactivate{" "}
              <b>
                {tableRecord?.first_name ?? ""} {tableRecord?.last_name ?? ""}
              </b>
              ?
            </>
          )
        }
        paragraph={
          trailData
            ? "There is data associated with this user (e.g. activity history). You can deactivate this user if you dont need them anymore."
            : "The user will be archived and will lose access to the system. You can activate them later if needed."
        }
        btnText="Deactivate user"
        btnApi={deactivateFunction}
        loading={loading}
      />
    ),
    width: 520,
    onCancel: () => setConfirmDeactivate(false),
  };

  const resetFilters = () => {
    dispatch(setFiltersOptionsSystem("status", undefined));
    dispatch(setFiltersOptionsSystem("search", undefined));
    setFilterBtn(false);
    setSearchValue("");
  };

  useEffect(() => {
    if (selectedFilters?.status || selectedFilters?.search) {
      const payload = {
        offset: pagination - 1,
        limit: limit,
        type: ["admin", "manager"],
        status: selectedFilters?.status ?? undefined,
        search: selectedFilters?.search ?? undefined,
      };
      dispatch(getMembersList(payload));
    } else {
      teamDispatch();
    }
  }, [dispatch, selectedFilters, pagination, limit]);

  const handleSearch = useCallback(
    debounce((value) => {
      dispatch(setFiltersOptionsSystem("search", value));
    }, 500),
    []
  );

  const handleChange = (event: { target: { value: any } }) => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  };

  const onPageSize = useCallback((currentPage: number, pageSize: number) => {
    setLimit(pageSize);
  }, []);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPagination(pageNumber);
    setLimit(pageSize);
  }, []);

  return (
    <Spin indicator={antIcon} size="large" spinning={membersListLoading}>
      <Card className="leftTabCard">
        <div className="cardHead">
          <div className="cardHeadLeft">
            <h3>Operations team</h3>
            <p>Manage operations users and their roles here.</p>
          </div>

          <div className="filter_right">
            <div className="search_filter">
              <Input
                placeholder="Search"
                prefix={<SearchIcon />}
                onChange={handleChange}
                value={searchValue}
              />
            </div>
            <div className="filter_btn">
              <Button onClick={() => setFilterBtn(!filterBtn)}>
                Filter
                <Image
                  src="/images/filter-icon.svg"
                  alt="Filter"
                  width={16}
                  height={16}
                  preview={false}
                />
              </Button>
            </div>
            {accountData?.type === "admin" && (
              <div className="add_new_btn">
                <Button
                  className="icon_dark_btn"
                  icon={<PlusIcon />}
                  onClick={() => navigate("/add-user")}
                >
                  Add user
                </Button>
              </div>
            )}
          </div>

          {filterBtn && (
            <div className="filter_div">
              <div className="filter_radio">
                <Radio.Group
                  defaultValue="active"
                  onChange={({ target: { value } }: RadioChangeEvent) =>
                    dispatch(setFiltersOptionsSystem("status", value))
                  }
                >
                  <Radio.Button value="active">Active</Radio.Button>
                  <Radio.Button value="deactivated">Deactivated</Radio.Button>
                </Radio.Group>
              </div>
              <div className="reset_btn">
                <Button onClick={resetFilters} type="text">
                  <Image
                    src="/images/close-icon-grey.svg"
                    alt="Close"
                    width={10}
                    height={10}
                    preview={false}
                  />{" "}
                  Reset Filters
                </Button>
              </div>
            </div>
          )}
        </div>

        <div className="cardContent">
          {membersList?.data?.length > 0 ? (
            <Table
              className="dashboard_table"
              columns={columns}
              dataSource={membersList?.data}
              pagination={{
                // showSizeChanger: membersList?.data?.length > limit,
                defaultPageSize: limit,
                total: membersList?.count,
                current: pagination,
                onChange: onPageChange,
                onShowSizeChange: onPageSize,
              }}
              // onRow={onClickRow}
              rowKey="id"
            />
          ) : (
            <EmptyCard
              image={<EmptyDataIcon />}
              title="There are no operational users."
              description='Click the "Add user" button to add a new user.'
              buttonText="Add user"
              navigateTo="/add-user"
            />
          )}
        </div>
        <Popup {...confirmDeleteMember} />
        <Popup {...confirmDeactivateMember} />
      </Card>
    </Spin>
  );
}
