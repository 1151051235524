import { BASE_URLS, ENVIRONMENT_MODE } from "../../../config/constants";
import { AxiosResponse } from "axios";
import axios from "../../../config/axiosInterceptor";
import { AddMember } from "./types";

const baseURL = BASE_URLS[ENVIRONMENT_MODE].REACT_APP_API_BASE_URL;

export const addMember = (
  payload: AddMember
): Promise<AxiosResponse<AddMember>> => {
  return axios.post(`${baseURL}addMember`, payload);
};

export const updateMemberProfile = (
  payload: AddMember,
  memBerID: string
): Promise<AxiosResponse<AddMember>> => {
  return axios.patch(`${baseURL}updateMemberProfile/${memBerID}`, payload);
};

export const deleteMember = (id: string): Promise<AxiosResponse<string>> => {
  return axios.delete(`${baseURL}memebers/${id}`);
};

export const deactivateMemberProfile = (
  payload: { status: string },
  memBerID: string
): Promise<AxiosResponse<{ status: string }>> => {
  return axios.patch(`${baseURL}updateMemberProfile/${memBerID}`, payload);
};
