import { Row, Col, Button, Card, Form, Input, Spin } from "antd";
import { useForm } from "react-hook-form";
import InputPassword from "../../../molecules/FormItems/InputPassword";
import { useAppSelector } from "../../../config/hooks";
import { useState } from "react";
import { changePassword } from "../ducks/services";
import { EyeInvisibleOutlined, EyeOutlined } from "../../../atoms/CustomIcons";
import SuccessMessage from "../../../atoms/Toasts/SuccessMessage";
import ErrorMessage from "../../../atoms/Toasts/ErrorMessage";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { AxiosError } from "axios";

interface FormValues {
  current_password: string;
  new_password: string;
  confirm_password: string;
}
const antIcon = <Loading3QuartersOutlined spin />;

export default function ChangePassword() {
  const accountData = useAppSelector((state) => state?.account?.accountData);
  const [loading, setLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const currentPassword = watch("current_password");

  const onSubmit = (val: {
    current_password: string;
    new_password: string;
  }) => {
    setLoading(true);
    const payload = {
      userId: accountData?.id,
      email: accountData?.email,
      oldPassword: val?.current_password,
      newPassword: newPassword,
    };

    changePassword(payload)
      .then(() => {
        setLoading(false);
        SuccessMessage("Password changed.");
      })
      .catch((e: unknown) => {
        if (e instanceof AxiosError) {
          setLoading(false);
          ErrorMessage(
            e?.response?.data?.error?.message ?? "Something went wrong"
          );
        } else {
          setLoading(false);
          ErrorMessage("Something went wrong");
        }
      });
  };

  return (
    <Spin indicator={antIcon} size="large" spinning={loading}>
      <Card className="leftTabCard">
        <div className="cardHead">
          <div className="cardHeadLeft">
            <h3>Change Password</h3>
            <p>Here, you can update your account password.</p>
          </div>
        </div>
        <div className="cardContent">
          <Form onFinish={handleSubmit(onSubmit)}>
            <Row gutter={[0, 32]}>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4 className="password_label">
                      Current Password <span className="red_color">*</span>
                    </h4>
                    <p style={{ color: "#484B53" }}>
                      Confirm your current password
                    </p>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <InputPassword
                        fieldname="current_password"
                        label=""
                        control={control}
                        initValue=""
                        iProps={{
                          placeholder: "Enter your password",
                          autoComplete: "new-password",
                        }}
                        isRequired
                        rules={{
                          required: "Current password is required.",
                        }}
                        validate={errors.current_password && "error"}
                        validMessage={
                          errors.current_password &&
                          errors.current_password.message
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px">
                    <h4 className="password_label">
                      New Password <span className="red_color">*</span>
                    </h4>
                    <p style={{ color: "#484B53" }}>
                      The password must be <br />a minimum of 8 characters.
                    </p>
                  </Col>
                  <Col>
                    <div className="leftCardDiv">
                      <Form.Item
                        label=""
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: "New password is required.",
                          },
                        ]}
                      >
                        <Input.Password
                          placeholder="Enter new password"
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="formControl"
                          iconRender={(visible: boolean) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="leftCardDiv">
                      <Form.Item
                        label=""
                        name="confirmPassword"
                        dependencies={["password"]}
                        rules={[
                          {
                            required: true,
                            message: "Confirm new password is required.",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Passwords don't match")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder="Confirm new password"
                          onChange={(e) => setNewPassword2(e.target.value)}
                          className="formControl"
                          iconRender={(visible: boolean) =>
                            visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row gutter={21}>
                  <Col flex="0 0 273px" />
                  <Col>
                    <div className="leftCardDiv">
                      <Button
                        htmlType="submit"
                        type="primary"
                        className="icon_dark_btn"
                        disabled={
                          !newPassword || !newPassword2 || !currentPassword
                        }
                      >
                        Change password
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Card>
    </Spin>
  );
}
